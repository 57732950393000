import React, { useState } from 'react'
import { AlertDialog, AlertDialogContent } from '../../components/ui/alert-dialog'
import Navbar from '../../layouts/Navbar'
import { useNavigate, useParams } from 'react-router-dom'
import signup from '../../assets/images/gadgets/illustration_signup.svg'
import done from '../../assets/images/gadgets/illustration_party.svg'
import create from '../../assets/images/gadgets/illustration_details.svg'
import { ArrowLeft, ChevronLeft, ChevronRight } from 'lucide-react'
import { Button } from '../../components/ui/button'
import { useTranslation } from 'react-i18next'

export const ExplanationInvite = () => {
    const {t} = useTranslation()
    const {page} = useParams()
    const navigate = useNavigate()

    const [step, setstep] = useState(1);
    const [steps, setsteps] = useState(3);

    const Partner = () => {
        return (
            <div className='flex items-center justify-center flex-col gap-6 w-full'>
                <img src={signup} className='w-[150px] max-h-[150px] h-full object-contain'/>
                <h2 className='font-bold text-xl'>{t("explanation.o_partner.title")}</h2>
                <p className='text-center'>{t("explanation.o_partner.description")}</p>
            </div>
        )
    }

    const Department = () => {
        return (
            <div className='flex items-center justify-center flex-col gap-6 w-full'>
                <img src={signup} className='w-[150px] max-h-[150px] h-full object-contain'/>
                <h2 className='font-bold text-xl'>{t("explanation.o_department.title")}</h2>
                <p className='text-center'>{t("explanation.o_department.description")}</p>
            </div>
        )
    }

    const Create = () => {
        return (
            <div className='flex items-center justify-center flex-col gap-6 w-full'>
                <img src={create} className='w-[150px] max-h-[150px] h-full object-contain'/>
                <h2 className='font-bold text-xl'>{t("explanation.o_create.title")}</h2>
                <p className='text-center'>{t("explanation.o_create.description")} <span className='font-medium'>{t("explanation.o_create.description_p2")}</span>{t("explanation.o_create.description_p3")}<br></br><br></br>
                {t("explanation.o_create.description_p4")}</p>
            </div>
        )
    }

    const Done = () => {
        return (
            <div className='flex items-center justify-center flex-col gap-6 w-full'>
                <img src={done} className='w-[150px] max-h-[150px] h-full object-contain'/>
                <h2 className='font-bold text-xl'>{t("explanation.o_done.title")}</h2>
                <p className='text-center'>{t("explanation.o_done.description")}</p>
                <Button onClick={()=>{navigate(-1)}}>{t("explanation.o_done.btn_done")}</Button>
            </div>
        )
    }


  return (
    <div className='bg-neutral-200 h-screen w-screen relative'>
        <div className='relative z-[2]'>
        <Navbar />
        <AlertDialog open>
            <AlertDialogContent className='max-w-xl'>
                <button onClick={()=>{navigate(-1)}} className='w-10 h-10 absolute top-4 left-4 flex justify-center items-center -translate-x-4 -translate-y-4'><ArrowLeft className='w-5'/></button>
                
                <div className='flex items-center gap-8'>
                    <button onClick={()=>{setstep(p=>p-1)}} disabled={step===1} className='bg-black w-10 h-10 min-w-[40px] rounded-full flex justify-center items-center text-white disabled:bg-black/50'><ChevronLeft className='w-4'/></button>
                    {(step===1&&page==='partner')&&<Partner />}
                    {(step===1&&page==='afdeling')&&<Department />}
                    {step===2&&<Create />}
                    {step===3&&<Done />}
                    <button onClick={()=>{setstep(p=>p+1)}} disabled={step===steps} className='bg-black w-10 h-10 min-w-[40px] rounded-full flex justify-center items-center text-white disabled:bg-black/50'><ChevronRight className='w-4'/></button>
                </div>

                <div className='flex justify-center items-center gap-2 mt-4'>
                    <div className={`${step>=1?'bg-black':'bg-black/20'} w-2 h-2 rounded-full`}></div>
                    <div className={`${step>=2?'bg-black':'bg-black/20'} w-2 h-2 rounded-full`}></div>
                    <div className={`${step>=3?'bg-black':'bg-black/20'} w-2 h-2 rounded-full`}></div>
                </div>
            </AlertDialogContent>
        </AlertDialog>
        </div>

        <div className='absolute w-full h-full bg-tifo/50 z-[1]'/>
        <img src='https://img.aso.fr/core_app/img-cycling-tdf-jpg/grand-depart-2025/52787/0:0,2000:890-2000-0-60/4f928' className='absolute w-full h-full object-cover z-0'/>
    </div>
  )
}

import { cn } from '../../../lib/utils'
import React, { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
interface Props {
  value: 'company' | 'group' | 'groups';
  onValueChange: (value:'company' | 'group' | 'groups') => void;
}

const TabsRanking : React.FC<Props & HTMLAttributes<HTMLDivElement>> = ({value, onValueChange, className}) => {
  const {t} = useTranslation()
  return (
    <div className={cn('grid grid-cols-3 items-center gap-1 bg-black/40 h-10 rounded-full p-1 backdrop-blur-sm', className)}>
        <button onClick={()=>{ onValueChange('company') }} className={`${value === 'company' && '!bg-white/80 !text-tifo'} flex justify-center items-center gap-2 text-white text-sm font-medium h-full rounded-full px-2 hover:bg-white/40 duration-200`}>{t("prono.o_ranking.company")}</button>
        <button onClick={()=>{ onValueChange('group') }} className={`${value === 'group' && '!bg-white/80 !text-tifo'} flex justify-center items-center gap-2 text-white text-sm font-medium h-full rounded-full px-2 hover:bg-white/40 duration-200`}>{t("prono.o_ranking.group")}</button>
        <button onClick={()=>{ onValueChange('groups') }} className={`${value === 'groups' && '!bg-white/80 !text-tifo'} flex justify-center items-center gap-2 text-white text-sm font-medium h-full rounded-full px-2 hover:bg-white/40 duration-200`}>{t("prono.o_ranking.groups")}</button>
    </div>
  )
}

export default TabsRanking
import React, { useState } from "react"

import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "../../lib/utils"
import { Eye } from "lucide-react"

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {
  startEnhancer?: React.ReactNode
  classNameInput?: string
} 

const inputVariants = cva(
  "relative bg-slate-200",
  {
    variants: {
      variant: {
        default: "ring-offset-background focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
        dark: "bg-slate-300",
        transparent: "bg-white/20 text-white"
      }
    },
    defaultVariants: {
      variant: "default"
    }
  }
)

const Input = React.forwardRef<HTMLInputElement, InputProps>(
({ startEnhancer, variant, className, type, classNameInput, ...props }, ref) => {
  const [type_state, settype_state] = useState(type);
  const isEdge = window.navigator.userAgent.indexOf("Edg") > -1

  return (
    <div className={cn(inputVariants({ variant, className }))}>
      {startEnhancer && (
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          {startEnhancer}
        </div>
      )}
      <input
        type={type_state}
        className={cn(
          "flex h-10 w-full rounded-md bg-transparent px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
          startEnhancer ? "pl-9" : "", // Add left padding if startEnhancer exists
          variant === 'transparent' && '!placeholder-white/50',
          classNameInput
        )}
        ref={ref}
        {...props}
      />
      {(type==='password' && !isEdge) && (
        <button onMouseDown={()=>{settype_state('text')}} onTouchStart={()=>{settype_state('text')}} onTouchEnd={()=>{settype_state('password')}} onMouseUp={()=>{settype_state('password')}} className="absolute inset-y-0 right-2 opacity-50">
          <Eye className="w-4"/>
        </button>
      )}
    </div>
  )
}
)
Input.displayName = "Input"

export { Input }

import React, { useEffect, useState } from 'react'
import Footer from '../../layouts/Footer'
import { Button } from '../../components/ui/button'
import { Label } from '../../components/ui/label'
import { Input } from '../../components/ui/input'
import { useMutation } from '@tanstack/react-query'
import axios from '../../lib/axios'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import { AlertTriangle, Check, Loader2 } from 'lucide-react'
import useAuth from '../../hooks/useAuth'
import { useTranslation } from 'react-i18next'

const ResetPassword = () => {
    const navigate = useNavigate()
    const {setAuth} = useAuth()
    const {t, i18n} = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    const code = searchParams.get('code')
    const email = searchParams.get('email')
    const action = searchParams.get('action')

    const [password, setpassword] = useState('');
    const [password_2, setpassword_2] = useState('');

    const { mutate, reset, isPending, isError, isSuccess } = useMutation({
        mutationFn: async () => {
            const { data } = await axios.post(`password-reset/${code}`, {
                password: password,
                email: email,
                code: code,
                action: action
            })
            console.log(data);
            toast.loading(`${t("forgot-password.u wordt doorvewezen")}`, {id:'forward'})
            login()
            return true
        },
        onError: (e:any) => {
            if(e.response.data === 'Recovery link is expired'){ toast.error(t('forgot-password.deze link is vervallen')); reset(); return; }
            console.log(e);
            toast.error(`${t("forgot-password.doorverwijzen mislukt")}`, {id:'resetPassword'})
            setTimeout(()=>{ reset() }, 2000)
        }
    })

    const { mutate:login } = useMutation({
        mutationFn: async () => {
            const { data } = await axios.post(`authenticate`, {username:email.toLowerCase(), password})
            localStorage.setItem('authToken', data)
            const {data:me} = await axios.get(`me`)
            setAuth({data:me, status:'authenticated'})
            i18n.changeLanguage(me.m_sLanguage.toLowerCase())
            navigate('/')
            toast.remove('forward')
        },
        onError: () => {
            toast.error(t("forgot-password.doorverwijzen mislukt"), {id:'error-forward'})
            toast.remove('forward')
        }
    })

    function isValidPassword() {
        let isValid = false;

        if(password === password_2 && password.replace(/\s/g, '').length > 0){
            isValid = true
        }

        return isValid
    }

    useEffect(() => {
    
        function pressEnter(e) { 
          if(e.key!=='Enter'){return}
          if(!isValidPassword() || isPending || isError || isSuccess){return}
          mutate()
        }
        window.addEventListener("keypress", pressEnter)
    
        return () => {
          window.removeEventListener("keypress", pressEnter)
          reset()
        }
    }, [])

  return (
    <>
        <Toaster />
        <div className='w-full h-[88px] bg-tifo'></div>

        <div className='min-h-[calc(100dvh-88px)] grid md:grid-cols-2 gap-10 w-full bg-neutral-50'>
            <div className='p-8 pr-0 flex-1 hidden md:block'>
                <div className='w-full h-full rounded-xl overflow-hidden relative'>
                    <div className='absolute h-full w-full top-0 left-0 bg-gradient-to-tr from-tifo/90 to-tifo-light/10'></div>
                    <img className='w-full h-full object-cover' src="https://www.canyon.com/dw/image/v2/BCML_PRD/on/demandware.static/-/Library-Sites-canyon-shared/default/dwcb8cfa7a/images/blog/Pro-Sports/tour-de-france-2022-mvdp-yellow-jersey.jpg?sw=1064" alt="Tifogame"/>
                </div>
            </div>
            <div className='p-4 md:p-8 md:pl-0 flex items-center justify-center'>
                <div className='bg-white rounded-xl shadow-2xl shadow-black/10 p-6 max-w-xl flex flex-col gap-4'>
                    <h1 className='text-2xl font-bold text-center'>{t("forgot-password.wachtwoord vergeten")}</h1>
                    <p className='text-sm text-neutral-400 text-center'>{t("forgot-password.vul onderstaande velden in")}</p>
                    <div>
                        <Label htmlFor='password'>{t("forgot-password.wachtwoord")}</Label>
                        <Input onKeyDown={(e)=>{if(e.key==='Enter' && isValidPassword() && !isPending && !isError && !isSuccess){ mutate() }}} disabled={isSuccess} value={password} onChange={({currentTarget})=>{setpassword(currentTarget.value)}} placeholder={t("forgot-password.nieuw wachtwoord")} type='password' id='password'/>
                    </div>
                    <div>
                        <Label htmlFor='repeat'>{t("forgot-password.herhaal wachtwoord")}</Label>
                        <Input onKeyDown={(e)=>{if(e.key==='Enter' && isValidPassword() && !isPending && !isError && !isSuccess){ mutate() }}} disabled={isSuccess} value={password_2} onChange={({currentTarget})=>{setpassword_2(currentTarget.value)}} placeholder={t("forgot-password.nieuw wachtwoord")} type='password' id='repeat'/>
                    </div>
                    {isSuccess && <Link to="/"><Button className='w-full'>{t("forgot-password.naar inloggen")}</Button></Link>}
                    {!isSuccess && <Button onClick={()=>{ mutate() }} disabled={!isValidPassword() || isPending || isError || isSuccess} className='gap-2' variant={isError ? 'destructive' : 'default'}>
                        {isPending && <Loader2 className='w-4 h-4 animate-spin'/>}
                        {isSuccess && <Check className='w-4 h-4'/>}
                        {isError && <AlertTriangle className='w-4 h-4'/>}
                        {t("forgot-password.wachtwoord wijzigen")}
                    </Button>}
                </div>
            </div>
        </div>

        <Footer />
    </>
  )
}

export default ResetPassword
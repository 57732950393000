import { createContext, useEffect, useState } from "react";
import { Button } from "../components/ui/button";
import { Sheet, SheetClose, SheetContent } from "../components/ui/sheet";
import { Cookie } from "lucide-react";
import { getCookie, setCookie as addCookie } from "../lib/cookies";
import { addYears } from "date-fns";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";


const CookieContext = createContext<CookieContext | null>(null);

export const CookieProvider = ({ children } : { children:React.ReactNode}) => {
    const {t} = useTranslation()
    const location = useLocation()
    const [cookie, setCookie] = useState<boolean>(false);
    const [open_sheet, setopen_sheet] = useState(getCookie('cookie-tifo') ? false : true);

    useEffect(()=>{
        if(window.location.pathname === '/cookies'){ setopen_sheet(false); return; }
        setopen_sheet(getCookie('cookie-tifo') ? false : true)
    }, [location.pathname])

    return (
        <CookieContext.Provider value={{ cookie, setCookie }}>
            
            {children}

            <Sheet open={open_sheet}>
                <SheetContent className="w-fit h-fit mb-4 left-1/2 -translate-x-1/2 rounded-xl bg-tifo border-tifo-light" position="bottom" hideClose>
                    <div className="flex items-center gap-4 text-white text-sm">
                        <div>
                            <Cookie strokeWidth={1.5} className="!w-6 h-6 min-w-[24px]"/>
                        </div>
                        <div>
                            <p className="max-w-sm">{t("cookies.o_popup.desc")}</p>
                            <a href="/cookies" className="underline text-xs">{t("cookies.o_popup.info")}</a>
                        </div>
                        {/* <SheetClose onClick={()=>{setopen_sheet(false)}} asChild><button className="border rounded-full font-medium h-10 px-4 opacity-50 hover:opacity-60">Weigeren</button></SheetClose> */}
                        <SheetClose onClick={()=>{addCookie('cookie-tifo', '1', addYears(new Date(), 100)); setopen_sheet(false)}} asChild><button className="border rounded-full font-medium h-10 px-4 bg-white/20 hover:bg-white/30 duration-200">{t("cookies.o_popup.accept")}</button></SheetClose>
                    </div>
                </SheetContent>
            </Sheet>
        </CookieContext.Provider>
    )
}

export default CookieContext;
import ax from 'axios'

const BASE_URL = process.env.REACT_APP_API ||'http://10.62.0.124/tg1/'

const axios = ax.create({
    //baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : BASE_URL
    baseURL: process.env.NODE_ENV === 'development' ? BASE_URL : BASE_URL
})

// Add an interceptor to include the bearer token in the request headers
axios.interceptors.request.use(
    (config) => {
      // Retrieve the token from your preferred storage (e.g., localStorage)
      const authToken = localStorage.getItem('authToken');
  
      // If the token exists, add it to the 'Authorization' header
      if (authToken) {
        config.headers['Authorization'] = `Bearer ${authToken}`;
      }
  
      return config;
    },
    (error) => {
      // Handle any request error
      return Promise.reject(error);
    }
);

export default axios;
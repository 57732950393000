import React, { useState } from 'react'
import { useStateWithCallbackLazy } from 'use-state-with-callback';

import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import Navbar from '../../layouts/Navbar'
import { Button } from '../../components/ui/button'
import { Input } from '../../components/ui/input'
import { Check, ChevronRight, Key, Loader2, Lock, LogIn, Mail, MoveRight, Puzzle, User2 } from 'lucide-react'
import { Combobox } from '../../components/ui/combobox'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios from '../../lib/axios'
import { z, ZodError } from 'zod';
import toast, { Toaster } from 'react-hot-toast'
import { setCookie } from '../../lib/cookies'
import { addDays, addWeeks } from 'date-fns'
import useAuth from '../../hooks/useAuth'
import { delay } from '../../lib/utils'
import Login from '../../components/login/index'
import { useTranslation } from 'react-i18next'
import { Select, SelectTrigger, SelectValue, SelectContent, SelectGroup, SelectItem } from '../../components/ui/select'
import { AxiosError } from 'axios';

const Invite = () => {
  const {t, i18n} = useTranslation()
  const {auth, setAuth} = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
 
  const {partnername, actionid, code} = useParams()

  const [page, setpage] = useState<'new' | 'login' | 'register' | 'forgot-password'>(localStorage.getItem('authToken') ? 'login' : 'new');
  const [step, setstep] = useState(0);

  const [department, setdepartment] = useStateWithCallbackLazy('')
  // const [department, setdepartment] = useState('');
  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');

  const {data:partner}=useQuery<Partner | null>({
    queryKey: [`partner_${partnername}`],
    queryFn: async () => {
      try {
        const {data}=await axios.get(`partner/${partnername}`)
        return data
      } catch (error) {
        console.log(error);
        navigate('/')
        return null
      }
    }
  })

  const {data:departments, isLoading:isLoadingDepartments} = useQuery<PartnerDepartment[] | null>({
    queryKey: [`departments_${partnername}`],
    queryFn: async () => {
      try {
        if(auth.data.m_oDepartment.m_iDepartmentID > 0){ navigate(`/${partnername}`, { state:location.pathname }); return null; }
        // if(auth.data.m_oPartner.m_iPartnerID !== partner.m_iPartnerID){
        //   // Partner not the same; update partner
        //   console.log(auth.data.m_oPartner.m_iPartnerID, partner.m_iPartnerID);
        //   const { data:userUpdate } = await axios.patch('users', { userid: auth.data.m_iUserID, partnerid: partner.m_iPartnerID})
        //   console.log(userUpdate);
        // }
        if(!partner){ return null; }
        await delay(200)
        const {data} = await axios.get(`partners/${partner.m_iPartnerID}/departments`)
        if(data.length===1){
          setdepartment(data[0].m_iDepartmentID.toString(), () => { linkPartner() }); 
        }
        return data
      } catch (error) {
        console.log(error);
        return null
      }
    },
    enabled: !!auth.data && !!partner
  })

  const {data:invitation, refetch:getInvitation}=useQuery<PartnerUser | null>({
    queryKey: [`invite_${code}`],
    queryFn: async () => {
      try {
        const { data } = await axios.get(`invitation/${actionid}/${code}`)
        setCookie('get-invitation', `${actionid}/${code}`, addDays(new Date, 7))
        if(data){
          return data
        }
      } catch (error) {
        console.log(error)
        navigate('/')
        return null;
      }
    },
    enabled: false
  })

  function randomIntFromInterval(min:number, max:number) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const {mutate:createAccount, isPending}=useMutation({
    mutationFn: async () => {
      try {
        if(!partner){ return null }
        const {data} = await axios.post(`users`, {
          email: email.toLowerCase(),
          firstname: firstname,
          lastname: lastname,
          password: password,
          username: `${firstname}${lastname}${randomIntFromInterval(1,9)}`,
          language: i18n.language.toUpperCase(),
          partnerid: partner.m_iPartnerID
        })
        handleLogin()
        console.log(data)
      } catch (error) {
        
        if(error.response.status === 500){ toast.error(`${t("error.500_users_post")}`, {duration:5000}); return; }
        if(error.response.status === 400){ 
          if(error instanceof AxiosError){ 
            if(error.response.data.toLowerCase().includes('email')){
              toast.error(`${t("error.400_users_post-2")}`, {duration:5000}); return;
            }
            if(error.response.data.toLowerCase().includes('create')){
              toast.error(`${t("error.400_users_post-3")}`, {duration:5000}); return;
            }
          }
          toast.error(`${t("error.400_users_post")}`, {duration:5000}); return;
        }
        if(error.response.status === 409){ 
          if(error instanceof AxiosError){ 
            if(error.response.data.toLowerCase().includes('username')){
              toast.error(`${t("error.409_users_post-2")}`, {duration:5000}); return;
            }
          }
          toast.error(`${t("error.409_users_post")}`, {duration:5000}); return; 
        }

        console.log(error.response.status)
        toast.error(`${t("invite.toast_error_create")} ${error.response.data}`, {duration:5000})
        console.log(error.response.data)
      }
    }
  })

  const {mutate:handleLogin, isPending:isPendingLogin}=useMutation<boolean | null>({
    mutationFn: async () => {
      try {
        const {data} = await axios.post(`authenticate`, {
          username: email,
          password: password
        })
        localStorage.setItem('authToken', data)
        const {data:me} = await axios.get(`me`) 
        setCookie('email', email, addDays(new Date(), 7))
        setAuth({data:me, status:'authenticated'})
        if(me.m_oDepartment.m_iDepartmentID > 0){ navigate(`/${partnername}`, { state:location.pathname }); return null; }
        getInvitation()
        return true
      } catch (error) {
        toast.error(`${t("invite.toast_error_login")}`, {duration:5000})
        console.log(error.response.data)
        return null
      }
    }
  })

  const {mutate:resetPassword, isPending:isPendingResetPW, isSuccess:isSuccessResetPW }=useMutation({
    mutationFn: async () => {
      try {
        const { data } = await axios.post('password-reset', {
          username: email
        })
        toast.success(t('invite.request_password_success'), { id:'request-pw' })
        return data;
      } catch (error) {
        toast.error(t('invite.request_password_failed'), { id:'request-pw' })
        return false;
      }
    }
  })

  const {data:linkedPartner, mutate:linkPartner, isPending:isPendingPartner}=useMutation<boolean>({
    mutationFn: async () => {
      try {
        if(!partner){ return null; }
        const {data} = await axios.post(`partners-users`, {
          active: true,
          userid: auth.data.m_iUserID,
          partnerid: partner.m_iPartnerID,
          departmentid: department
        })
        toast.success(t("invite.toast_success_link"))
        //Reload User Context (remove warning no department)
        const {data:me} = await axios.get(`me`)
        if(me){
          setAuth({data:me, status:'authenticated'})
        }
        setTimeout(()=>{navigate(`/${partnername}`, { state:location.pathname })}, 2000)
        await delay(2000)
        return true
      } catch (error) {
        console.log(error);
        toast.error(`${t("invite.toast_error_link")} ${error.response.data}`, {duration:5000})
        return false
      }
    }
  })

  // Define your Zod schema
  const registrationSchema = z.object({
    firstname: z.string().min(1),
    lastname: z.string().min(1),
    email: z.string().email(),
    password: z.string().min(4),
  })

  function isRegistrationComplete() {
    try {
      // Validate the data against the schema
      registrationSchema.parse({firstname, lastname, email, password});
      return true;
    } catch (error) {
      if (error instanceof ZodError) {
        return false;
      }
      throw error;
    }
  }

  function handleNextStep() {
    if(!isRegistrationComplete()){
      setstep(prev=>{ if(prev!==2){ return prev+1 }else { return 0 } })
    } else {
      if(isPending || isPendingLogin){}
      else { createAccount() }
    }
  }

  if(auth && auth.data && auth.data.m_oDepartment && auth.data.m_oDepartment.m_iDepartmentID > 0){ 
    return <Navigate to={`/${partnername}`} replace/> 
  }

  return (
    <>
    <Login />
    <Toaster />
    <div className='relative z-[2] flex flex-col gap-4 justify-between items-center text-white h-full mx-4 max-h-dvh overflow-auto'>
      <Navbar className='relative'/>  

      {/* No auth found   */}
      {!auth.data && 
      <div className='max-w-5xl w-full mx-4'>
        {partner &&
        <div className='h-20 w-fit md:h-26 lg:h-32 rounded-md overflow-hidden bg-white/50 mb-4'>
          <img className='w-auto h-full object-contain' src={partner?.m_sLogo} alt={partnername}/>
        </div>
        }
        <h1 className='text-4xl md:text-6xl lg:text-9xl font-bold'>{t("invite.title")}</h1>
        <p className='text-base sm:text-lg mt-10'>{t("invite.description")} {partner?.m_sName && `${t("invite.description_p2")}`} <span className='font-semibold'>{partner?.m_sName}</span> {t("invite.description_p3")}<br></br>{t("invite.description_p4")}</p>
        
        {page==='new' &&
        <div className='mt-10 bg-black/10 backdrop-blur-md rounded-xl flex flex-col justify-center items-center gap-4 p-4'>
          <p className='text-lg font-semibold text-center'>{t('invite.already_account')}</p>
          <div className='flex items-center flex-wrap gap-2'>
            <button onClick={()=>{setpage('register')}} className='w-full md:w-fit justify-center border border-tifo-light bg-tifo-light text-tifo h-10 rounded-full px-3 font-medium flex items-center gap-2 group'>{t('invite.new_here')}<ChevronRight className='w-0 h-0 duration-200 group-hover:w-4 group-hover:h-4'/></button>
            <button onClick={()=>{setpage('login')}} className='w-full md:w-fit justify-center border border-tifo-light bg-tifo h-10 rounded-full px-3 font-medium flex items-center gap-2 group'>{t('invite.not_new_here')}<ChevronRight className='w-0 h-0 duration-200 group-hover:w-4 group-hover:h-4'/></button>
          </div>
        </div>
        }
        {page==='register' && 
        <div className='mt-10 bg-black/10 backdrop-blur-md rounded-xl'>
          <div className='flex items-center'>
            {/* <div onClick={() => { setstep(-1) }} className={`${step === -1 ? 'bg-white text-tifo' : 'bg-transparent text-white'} cursor-pointer p-4 rounded-t-xl duration-300 flex items-center gap-2`}>
              <Puzzle strokeWidth={step === -1 ? 1.5 : 1} className={`${step === -1 ? 'text-green-500' : 'text-white'} w-6 h-6 hidden sm:block`} />
              <p className='text-sm sm:text-base'>Afdeling</p>
            </div> 
            <div className='w-px h-9 bg-white/30'></div>*/}
            <div onClick={()=>{setstep(0)}} className={`${step === 0 ? 'bg-white text-tifo' :'bg-transparent text-white'} cursor-pointer p-4 rounded-t-xl duration-300 flex items-center gap-2`}>
              <Mail strokeWidth={step === 0 ? 1.5 : 1} className={`${step === 0 ? 'text-green-500' : 'text-white'} w-6 h-6 hidden sm:block`}/>
              <p className='text-sm sm:text-base'>{t("invite.email")}</p>
            </div>
            <div className='w-px h-9 bg-white/30'></div>
            <div onClick={() => { setstep(1) }} className={`${step === 1 ? 'bg-white text-tifo' : 'bg-transparent text-white'} cursor-pointer p-4 rounded-t-xl duration-300 flex items-center gap-2`}>
              <User2 strokeWidth={step === 1 ? 1.5 : 1} className={`${step === 1 ? 'text-green-500' :'text-white'} w-6 h-6 hidden sm:block`} />
              <p className='text-sm sm:text-base'>{t("invite.name")}</p>
            </div>
            <div className='w-px h-9 bg-white/30'></div>
            <div onClick={() => { setstep(2) }} className={`${step === 2 ? 'bg-white text-tifo' : 'bg-transparent text-white'} cursor-pointer p-4 rounded-t-xl duration-300 flex items-center gap-2`}>
              <Lock strokeWidth={step === 2 ? 1.5 : 1} className={`${step === 2 ? 'text-green-500' : 'text-white'} w-6 h-6 hidden sm:block`} />
              <p className='text-sm sm:text-base'>{t("invite.password")}</p>
            </div>
          </div>
          <div className='p-4 bg-white rounded-b-xl text-tifo flex-col sm:flex-row flex justify-between items-center gap-4'>
            <div className='w-full'>
              {/* {step === -1 && <Combobox selectedValue={department} onValueChange={(v)=>{setdepartment(v.value.toString())}} options={[{value:'', label:''}]} placeholder='Selecteer uw afdeling...' input_placeholder='Zoeken...' className='rounded-full bg-slate-200'/>} */}
              {step === 0 && <Input onKeyDown={(e)=>{if(e.key==='Enter'){ handleNextStep() }}} value={email} onChange={({currentTarget})=>{setemail(currentTarget.value)}} id='email' className='rounded-full' type='email' placeholder={t("invite.placeholder_email")}/>}
              {step === 1 && <div onKeyDown={(e)=>{if(e.key==='Enter'){ handleNextStep() }}} className='flex gap-2'><Input value={firstname} onChange={({currentTarget})=>{setfirstname(currentTarget.value)}} id='voornaam' className='rounded-full w-full' type='text' placeholder={window.innerWidth < 420 ? t("invite.placeholder_name_p2") :  t("invite.placeholder_name")} /><Input value={lastname} onChange={({currentTarget})=>{setlastname(currentTarget.value)}} id='familienaam' className='rounded-full w-full' type='text' placeholder={window.innerWidth < 420 ? t("invite.placeholder_lastname_p2") :  t("invite.placeholder_lastname")} /></div>}
              {step === 2 && <Input onKeyDown={(e)=>{if(e.key==='Enter'){ handleNextStep() }}} value={password} onChange={({currentTarget})=>{setpassword(currentTarget.value)}} id='password' className='rounded-full' type='password' placeholder={t("invite.placeholder_password")} />}
            </div>
            
            {isRegistrationComplete()
            ?<Button disabled={isPending || isPendingLogin} onClick={()=>{createAccount()}} variant='tifo' className='rounded-full gap-2 whitespace-nowrap'>
              {!isPendingLogin 
              ? <>{t("invite.btn_complete")} {isPending ? <Loader2 className='w-4 animate-spin'/> : <LogIn className='w-4'/>}</>
              : <><Loader2 className='w-4 animate-spin'/> {t("invite.btn_pending")}</>
              }
              </Button>
            :<Button onClick={()=>{ handleNextStep() }} variant='tifo' className='rounded-full gap-2'>{t("invite.btn_next")} <MoveRight className='w-4'/></Button>
            }
          </div>
        </div>
        }
        {page==='login' &&
        <div className='mt-10 bg-black/10 backdrop-blur-md rounded-xl'>
          <div className='flex items-center'>
            <div className={`bg-white text-tifo cursor-pointer p-4 rounded-t-xl duration-300 flex items-center gap-2`}>
              <Key strokeWidth={1.5} className={`text-green-500 w-6 h-6 hidden sm:block`} />
              <p className='text-sm sm:text-base'>{t("invite.login_data")}</p>
            </div>
          </div>
          <div className='p-4 bg-white rounded-b-xl text-tifo flex-col sm:flex-row flex justify-between items-center gap-4'>
            <div className='w-full'>
              <div className='flex gap-2'>
                <Input value={email} onChange={({currentTarget})=>{setemail(currentTarget.value)}} id='email' className='rounded-full w-full' type='email' placeholder={t("invite.placeholder_email")} />
                <Input value={password} onChange={({currentTarget})=>{setpassword(currentTarget.value)}} id='password' className='rounded-full w-full' type='password' placeholder={t("invite.placeholder_password")} />
              </div>
              <div className='flex justify-end'>
                <button onClick={()=>{setpage('forgot-password')}} className='text-sm font-medium hover:underline'>{t('invite.forgot_password')}</button>
              </div>
            </div>
            <Button disabled={isPendingLogin} onClick={()=>{handleLogin()}} variant='tifo' className='rounded-full gap-2 whitespace-nowrap'>{isPendingLogin ? <><Loader2 className='w-4 animate-spin'/> {t("invite.btn_pending")}</> :<>{t("invite.btn_login")} <MoveRight className='w-4'/></>}</Button>
          </div>
        </div>
        }
        {page==="forgot-password" &&
        <div className='mt-10 bg-black/10 backdrop-blur-md rounded-xl'>
          <div className='flex items-center'>
            <div className={`bg-white text-tifo cursor-pointer p-4 rounded-t-xl duration-300 flex items-center gap-2`}>
              <Key strokeWidth={1.5} className={`text-green-500 w-6 h-6 hidden sm:block`} />
              <p className='text-sm sm:text-base'>{t('invite.forgot_password')}</p>
            </div>
          </div>
          <div className='p-4 bg-white rounded-b-xl text-tifo flex-col sm:flex-row flex justify-between items-center gap-4'>
            <div className='w-full'>
              <div className='flex gap-2'>
                <Input value={email} onChange={({currentTarget})=>{setemail(currentTarget.value)}} id='email' className='rounded-full w-full' type='email' placeholder={t("invite.placeholder_email")} />
              </div>
            </div>
            <Button disabled={isPendingResetPW} onClick={()=>{resetPassword()}} variant='tifo' className='rounded-full gap-2 whitespace-nowrap'>
              {isPendingResetPW && <Loader2 className='w-4 h-4 animate-spin'/>} 
              {isSuccessResetPW && <Check className='w-4 h-4'/>}
              {t('invite.request_password')}
            </Button>
          </div>
        </div>
        }

        {page==='register' && <p className='mt-4 text-right opacity-70 text-sm'>{t("invite.register_desc")} <span onClick={()=>{setpage('login')}} className='font-semibold cursor-pointer duration-200 hover:text-tifo-light underline'>{t("invite.register_desc_p2")}</span></p>}
        {page==='login' && <p className='mt-4 text-right opacity-70 text-sm'>{t("invite.login_desc")} <span onClick={()=>{setstep(0); setpage('register')}} className='font-semibold cursor-pointer duration-200 hover:text-tifo-light hover:underline'>{t("invite.login_desc_p2")}</span></p>}
        {page==='forgot-password' && <p className='mt-4 text-right opacity-70 text-sm'><span onClick={()=>{setstep(0); setpage('login')}} className='font-semibold cursor-pointer duration-200 hover:text-tifo-light hover:underline'>{t('invite.back_login')}</span></p>}

      </div>
      }

      {/* Auth found */}
      {auth.data &&
      <div className='max-w-5xl w-full mx-4'>
        <h1 className='text-4xl md:text-9xl font-bold'>{t("invite.department")}</h1>
        <p className='text-base sm:text-lg mt-10'>{t("invite.department_desc")}<br></br>{t("invite.department_desc_p2")}</p>
        <div className='mt-10 bg-black/10 backdrop-blur-md rounded-xl'>
          <div className='flex items-center'>
            <div className={`bg-white text-tifo cursor-pointer p-4 rounded-t-xl duration-300 flex items-center gap-2`}>
              <Puzzle strokeWidth={1.5} className={`text-green-500 w-6 h-6 hidden sm:block`} />
              <p className='text-sm sm:text-base'>{t("invite.department")}</p>
            </div>
          </div>
          <div className='p-4 bg-white rounded-b-xl text-tifo flex-col sm:flex-row flex justify-between items-center gap-4'>
            {(isLoadingDepartments || isPendingPartner) ? <p className='flex items-center gap-1'><span><Loader2 className='w-4 h-4 animate-spin'/></span>We zetten uw bedrijfspagina klaar. Even geduld...</p> :
            <>
            <div className='w-full'>
              <div className='flex gap-2'>
                <Combobox selectedValue={department} onValueChange={(v)=>{setdepartment(v.value.toString(), () => {})}} options={departments ? departments.map(d=>({value:d.m_iDepartmentID, label:d.m_sName})) :[]} placeholder={isLoadingDepartments ? t("invite.department_loading") : t("invite.department_placeholder")} input_placeholder={t("invite.search_placeholder")} className='rounded-full bg-slate-200'/>              
              </div>
            </div>
            {!linkedPartner 
            ? <Button onClick={()=>{linkPartner()}} disabled={department===''||isPendingPartner} variant='tifo' className='rounded-full gap-2 whitespace-nowrap'>{isPendingPartner ? <Loader2 className='w-4 animate-spin'/> :<Check className='w-4'/>} {t("invite.btn_complete_registration")}</Button>
            : <>{t("invite.btn_referring")}</>}
            </>}
          </div>
          
        </div>
      </div>
      }

      <i></i>
    </div>
    
    <div className='fixed z-[2] flex gap-1 bottom-4 left-1/2 -translate-x-1/2'>
      <div className={`${!auth.data ? 'bg-white' : 'bg-white/30'} w-10 h-1 rounded-full `}></div>
      <div className={`${auth.data ? 'bg-white' : 'bg-white/30'} w-10 h-1 rounded-full `}></div>
    </div>

    <div className='relative z-[2] flex justify-end p-4'>
      <Select onValueChange={(v)=>{ i18n.changeLanguage(v.toLowerCase()) }} value={i18n.language.toLowerCase()}>
        <SelectTrigger className='w-fit bg-transparent rounded-md border-white text-white'>
          <SelectValue placeholder="Selecteer een taal..."/>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="nl">Nederlands</SelectItem>
            <SelectItem value="en">English</SelectItem>
            <SelectItem value="fr">Francais</SelectItem>
            <SelectItem value="de">Deutsch</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>

    <div className='z-[1] w-screen h-screen fixed top-0 left-0 bg-gradient-radial to-black/80 from-tifo-light/10 backdrop-blur-sm'></div>
    {partner?.m_sBanner.includes('webm')
    ? <video className='z-[0] w-screen h-screen object-cover fixed top-0 left-0' autoPlay={true} muted loop><source src={partner.m_sBanner}/></video>
    : <img className='z-[0] w-screen h-screen object-cover fixed top-0 left-0' src={partner?.m_sBanner || 'https://www.canyon.com/dw/image/v2/BCML_PRD/on/demandware.static/-/Library-Sites-canyon-shared/default/dwcb8cfa7a/images/blog/Pro-Sports/tour-de-france-2022-mvdp-yellow-jersey.jpg?sw=1064'} alt='background' />
    }
    </>
  )
}

export default Invite
import React, { useState, useEffect, useRef } from 'react'
import { AlertTriangle, Bell, Building2, Grip, Link2, LogOut, Menu, Settings, User, User2, Users, X } from 'lucide-react'
import logo from '../assets/logo/logo_white.svg'
import { useDispatch, useSelector } from 'react-redux'
import { setLoginPopup } from '../redux/actions'
import { RootState } from '../redux'
import useAuth from '../hooks/useAuth'
import { Link, useLocation } from 'react-router-dom'
import { useClickAway, useWindowScroll } from "@uidotdev/usehooks";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '../components/ui/dropdown-menu'
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogTrigger } from '../components/ui/alert-dialog'
import mergeRefs, { cn, convertToUrl } from '../lib/utils'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import axios from '../lib/axios'
import { Select, SelectTrigger, SelectValue, SelectContent, SelectGroup, SelectItem } from '../components/ui/select'

type LinksProps = {
    onClickLink?: () => void;
}

const Login = () => {
    const {t} = useTranslation()
    const {auth, setAuth} = useAuth()
    const dispatch = useDispatch()
    return (
        <>
        {!auth.data 
        ? 
        <>
        <button onClick={()=>{dispatch(setLoginPopup(true))}} className='bg-tifo/50 border border-white/20 flex justify-center items-center rounded-full w-9 h-9 hover:bg-tifo/70 duration-300'>
            <User2 className='w-5 text-white'/>
        </button>
        </>
        : 
        <>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <div className='flex items-center gap-2 cursor-pointer outline outline-tifo/70 outline-0 rounded-full duration-300 relative'>
                        <div className='w-9 h-9 rounded-full bg-tifo/50 backdrop-blur-sm border border-white/20 font-semibold flex justify-center items-center text-white text-sm'>{auth.data.m_sFirstName.charAt(0)}</div>
                        {((auth.data.m_oPartner.m_sName==="") || (auth.data.m_oPartner.m_sName!=="" && !auth.data.m_oPartner.m_iPartnerID)) && <div className='absolute w-4 min-w-[1rem] h-4 bg-red-600 top-0 left-0 rounded-full -translate-x-1 -translate-y-1 text-white font-semibold flex justify-center items-center text-xs'>!</div>}
                    </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent className='max-w-sm w-full'>
                    <div className='p-4 flex items-center gap-2'>
                        <div className='w-10 h-10 rounded-full bg-tifo text-tifo-light flex justify-center items-center font-bold text-lg select-none'>{auth.data.m_sFirstName.charAt(0)}</div>
                        <div>
                            <DropdownMenuLabel className='text-base pb-0 font-bold'>{auth.data.m_sFirstName} {auth.data.m_sLastName}</DropdownMenuLabel>
                            <div className='px-2 pb-1.5'>
                                <p className='text-sm text-neutral-400'>{auth.data.m_sEmail}</p>
                            </div>
                        </div>
                    </div>
                    

                    <DropdownMenuSeparator/>

                    {!auth.data.m_oDepartment.m_iDepartmentID && 
                    <>
                    <DropdownMenuGroup className='p-2 text-red-600 hover:bg-transparent'>
                        <DropdownMenuItem className='px-4 py-3 font-medium hover:bg-transparent'>
                            <AlertTriangle className='w-4 h-4 mr-2'/>
                            {(auth.data.m_oPartner.m_sName==="") && <span>{t("navigation.no_company")}</span>}
                            {(auth.data.m_oPartner.m_sName!=="" && !auth.data.m_oDepartment.m_iDepartmentID) && <span>{t("navigation.no_department")}</span>}
                        </DropdownMenuItem>
                    </DropdownMenuGroup>
                    <DropdownMenuSeparator/>
                    </>
                    }

                    <DropdownMenuGroup className='p-2 flex flex-col'>
                        <Link to="/profile"><DropdownMenuItem className='px-4 py-3 font-medium'><User className='mr-2 h-4 w-4'/><span>{t("navigation.profile")}</span></DropdownMenuItem></Link>
                        {(auth.data.m_oPartner.m_sName==="") && <Link to="/uitleg/uitnodiging/partner"><DropdownMenuItem className='px-4 py-3 font-medium'><Building2 className='mr-2 h-4 w-4'/><span>{t("navigation.link_company")}</span></DropdownMenuItem></Link>}
                        {(!auth.data.m_oDepartment.m_iDepartmentID) && <Link to="/uitleg/uitnodiging/afdeling"><DropdownMenuItem className='px-4 py-3 font-medium'><Building2 className='mr-2 h-4 w-4'/><span>{t("navigation.link_department")}</span></DropdownMenuItem></Link>}
                    </DropdownMenuGroup>

                    <DropdownMenuSeparator />

                    <DropdownMenuGroup className='p-2 flex flex-col'>
                        <DropdownMenuItem className='px-4 py-3 font-medium' onClick={()=>{localStorage.removeItem('authToken'); setAuth({data: null,status: null})}}><LogOut className="mr-2 h-4 w-4" /><span>{t("navigation.logout")}</span></DropdownMenuItem>
                    </DropdownMenuGroup>
                    
                </DropdownMenuContent>
            </DropdownMenu>
        </>
        }
        </>
    )
}

const Links : React.FC<LinksProps> = ({onClickLink}) => {
    const {auth} = useAuth()    
    const {t} = useTranslation()

    return (
        <div className='lg:bg-tifo/50 lg:backdrop-blur-sm lg:py-2 lg:px-4 lg:h-10 lg:rounded-full flex lg:items-center gap-2 lg:gap-4 justify-center lg:border border-white/20 flex-col lg:flex-row'>
            {auth.data && <li onClick={()=>{onClickLink && onClickLink()}} className='flex items-center py-2 lg:py-0'><a href={`/${auth.data?.m_oPartner.m_sUrl ? `${convertToUrl(auth.data.m_oPartner.m_sUrl)}#events` : ''}`} className='text-white text-base lg:text-sm font-medium whitespace-nowrap'>{t("navigation.events")}</a></li>}
            {auth.data && <li onClick={()=>{onClickLink && onClickLink()}} className='flex items-center py-2 lg:py-0'><Link to="/klassementen" className='text-white text-base lg:text-sm font-medium whitespace-nowrap'>{t("navigation.ranking")}</Link></li>}
            {auth.data && <li onClick={()=>{onClickLink && onClickLink()}} className='flex items-center py-2 lg:py-0'><Link to="/profile#voorspellingen" className='text-white text-base lg:text-sm font-medium whitespace-nowrap'>{t("navigation.predictions")}</Link></li>}
        </div>
    )
}

type NavbarProps = {
    className?: string
}

const Navbar = ({className}:NavbarProps) => {
    const {i18n} = useTranslation()
    const {auth} = useAuth()
    const [{ y }] = useWindowScroll();
    const [show_navigation, setshow_navigation] = useState(false);
    const prevScrollPos = useRef(window.scrollY)
    const [direction, setdirection] = useState<'up' | 'down'>('up');
    const navRef = useClickAway(()=>{if(window.innerWidth < 1024){ setshow_navigation(false) }})

    useEffect(()=>{
        const currentScrollPos = y;
        if(prevScrollPos.current > currentScrollPos){
            //user scrolled up
            setdirection('up')
        }
        else {
            //user scrolled down
            setdirection('down')
        }
        prevScrollPos.current = currentScrollPos;
    }, [y])

    const { data:partner } = useQuery<Partner | null>({
        queryKey: [`partner_${auth.data?.m_oPartner?.m_iPartnerID}`],
        queryFn: async () => {
            try {
                const { data } = await axios.get(`partners/${auth.data?.m_oPartner?.m_iPartnerID}`)
                return data;
            } catch (error) {
                console.log(error);
                return null
            }
        },
        enabled: !!auth.data?.m_oPartner.m_iPartnerID
    })
    

    return (
    <>
    <div className={cn(`${y === 0 ? '' : (((y < 100) || direction === 'up') ? 'bg-tifo py-6 delay-200' : 'bg-tifo/0 h-0 p-0 opacity-0 overflow-hidden -translate-y-full')} fixed duration-500 top-0 z-50 w-full flex justify-center h-[88px]`, className)}>
        <div className='mx-4 max-w-5xl w-full grid grid-cols-2 lg:grid-cols-3 gap-0 xl:gap-4 items-center'>       
            <div className='flex items-center gap-4'>
                <button onClick={()=>{setshow_navigation(true)}} className='flex w-9 min-w-[36px] h-9 lg:hidden items-center justify-center rounded-full bg-tifo/50 backdrop-blur-sm border border-white/20'><Grip className='text-white w-5' strokeWidth={1}/></button>
                <Link to="/">
                    {partner ? <img className='h-9' src={partner.m_sLogo}/> : <img className='h-9' src={logo} alt="Logo"/>}
                </Link>
            </div>

            <div className='hidden lg:flex justify-center items-center'>
                {auth.data && <Links />}
            </div>

            <div className='flex justify-end items-center'>
                <Login />

                {/* {auth.data && <button className='flex justify-center items-center rounded-full w-10 h-10 bg-transparent hover:bg-tifo/70 duration-300 relative'>
                    <div className='absolute w-4 min-w-[1rem] h-4 bg-red-600 top-0 right-0 rounded-full text-white font-semibold flex justify-center items-center text-xs'>1</div>
                    <Bell className='w-5 text-white'/>
                </button>} */}
            </div>
        </div>
    </div>
    
    <div ref={mergeRefs(navRef)} className={`fixed h-[100dvh] ${show_navigation ? 'w-10/12' : 'w-0'} z-50 bg-tifo/90 backdrop-blur-lg top-0 left-0 overflow-hidden duration-500 flex flex-col`}>
        <div className='h-[80px] flex items-center mx-4'>
            <button onClick={()=>{setshow_navigation(false)}} className='translate-y-1 flex w-9 h-9 min-w-[36px] lg:hidden items-center justify-center rounded-full bg-white/10 backdrop-blur-sm border border-white/20'><X className='text-white w-5' strokeWidth={1.5}/></button>
        </div>
        <div className='m-4 h-full flex flex-col justify-between'>
            <Links onClickLink={()=>{setshow_navigation(false)}}/>
            
            <Select onValueChange={(v)=>{ i18n.changeLanguage(v.toLowerCase()) }} value={i18n.language.toLowerCase()}>
                <SelectTrigger className='w-fit bg-transparent rounded-md border-tifo-light text-white'>
                  <SelectValue placeholder="Selecteer een taal..."/>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="nl">Nederlands</SelectItem>
                    <SelectItem value="en">English</SelectItem>
                    <SelectItem value="fr">Francais</SelectItem>
                    <SelectItem value="de">Deutsch</SelectItem>
                  </SelectGroup>
                </SelectContent>
            </Select>
        </div>
    </div>
    
    </>
  )
}

export default Navbar
import React, { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import img from '../../assets/images/bg/001.jpg';
import { Button } from '../../components/ui/button';
import tvh from '../../assets/logo/tvh.png'
import gifts from '../../assets/images/gadgets/gifts.png'
import Slider from '../../components/pages/partner/Slider';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../components/ui/table';
import SmallRankingsTable from '../../components/rankings/SmallRankingsTable';
import { Link  } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query';
import axios from '../../lib/axios';
import { convertToNormal, convertToUrl, isInterface } from '../../lib/utils';
import { format, isToday, isValid } from 'date-fns';
import useAuth from '../../hooks/useAuth';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../components/ui/hover-card';
import { Check } from 'lucide-react';
import { Skeleton } from '../../components/ui/skeleton';
import { getTranslationString } from '../../lib/translation';
import { useTranslation } from 'react-i18next';
import Footer from '../../layouts/Footer';
import Prizes, { groupPrizes } from './prizes/Prizes';
import { getCycloPartnerConfig } from '../../config/cyclogame-partner-config';

const Winner = ({pronoId}:{pronoId:number}) => {
    const {data:winnersName} = useQuery<string>({
        queryKey: [`winner_prono_${pronoId}`],
        queryFn: async () => {
            try {
                const {data} = await axios.get(`pronos/${pronoId}/results`)
                return (data.find(i=>i.m_sPosition.toString()==='1')?.m_oCompetitor.m_sName) || ''
            } catch (error) {
                return ''
            }
        }
    })
    return (
        <p>{winnersName}</p>
    )
}

const Points = ({pronoId}:{pronoId:number}) => {
    const {auth} = useAuth()

    const {data:points} = useQuery<number>({
        queryKey: [`points_prono_${pronoId}`],
        queryFn: async () => {
            try {
                const {data} = await axios.get(`user-prono-results?pronoid=${pronoId}&userid=${auth.data.m_iUserID}`)
                var result = data.reduce(function (acc, obj) { return acc + obj.m_iPoints; }, 0);

                return result;
            } catch (error) {
                return 0
            }
        },
        enabled: !!auth.data
    })

    return (
        <p>{points}</p>
    )
}

const Row = ({prono, index}:{prono:Prono, index:number}) => {
    const {t, i18n} = useTranslation()
    const {auth} = useAuth()
    const navigate = useNavigate()

    const { data:picked } = useQuery({
        queryKey: [`prevPicked_${prono.m_iPronoID}`],
        queryFn: async () => {
          try {
            const {data} = await axios.get(`user-prono-results?pronoid=${prono.m_iPronoID}&userid=${auth.data.m_iUserID}`)
            let results = data.filter(i=>parseFloat(i.m_sPosition)>0)
            if(results.length>0){return results}
            return null
          } catch (error) {
            console.log('')
            return null
          }
        },
        enabled: !!auth.data
    })

    return (
        <TableRow onClick={()=>{navigate(`${prono.m_iPronoID}`)}} className={`${isToday(new Date(prono.m_dtStart)) && 'bg-green-100'} cursor-pointer`}>
            <TableCell className='px-2 py-1 sm:p-4'>
                {index+1}
            </TableCell>
            <TableCell className='px-2 py-1 sm:p-4'>{isValid(new Date(prono.m_dtStart)) && format(new Date(prono.m_dtStart), "dd/MM")}</TableCell>
            <TableCell className='px-2 py-1 sm:p-4'>
                <p className='whitespace-nowrap'>{getTranslationString(i18n.language.toUpperCase() as Language, prono, "m_sDescriptionNL") || prono.m_sDescriptionNL}</p>
                {picked && <p className='flex items-center text-xs text-green-600/50'><Check className='w-3' strokeWidth={1.5}/> {t("event.filled")}</p>}
            </TableCell>
            <TableCell className='whitespace-nowrap'>{getTranslationString(i18n.language.toUpperCase() as Language, prono.m_oPronoType, "m_sNameNL") || prono.m_oPronoType.m_sNameNL}</TableCell>
            <TableCell className='px-2 py-1 sm:p-4'>{prono.m_sExtra1}</TableCell>
            <TableCell className='px-2 py-1 sm:p-4'>{prono.m_bClosed ? <Winner pronoId={prono.m_iPronoID}/> : ''}</TableCell>
            {auth.data && <TableCell className='px-2 py-1 sm:p-4'>
                {prono.m_bClosed ? <Points pronoId={prono.m_iPronoID}/> : ''}
            </TableCell>}
        </TableRow>
    )
}

const Event = () => {
    const {t, i18n} = useTranslation()
    const {auth} = useAuth()
    const {eventname, partnername} = useParams();
    const navigate = useNavigate()

    const {data:partner} = useQuery<Partner | null>({
        queryKey: [`partner_${partnername}`],
        queryFn: async () => {
            try {
                if(!partnername){return null}
                const {data} = await axios.get(`partner/${partnername}`)
                return data
            } catch (error) {
                console.log(error)
                return null
            }
        }
    }) 

    const {data:event, refetch:refetchEvent} = useQuery<EventClass | null>({
        queryKey: [`event_${eventname}`],
        queryFn: async () => {
            try {
                const {data} = await axios.get(`event/${eventname.toLowerCase()}`)
                if(Array.isArray(data)){throw new Error('multiple events found')}
                return data;
            } catch (error) {
                console.log(error)
                navigate('/')
                return null
            }
        }
    })

    useEffect(()=>{
        refetchEvent()
    }, [auth.status])

    const { data:pronos, refetch:refetchPronos } = useQuery<Prono[] | null>({
        queryKey: [`pronos_${eventname}`],
        queryFn: async () => {
            try {
                const {data} = await axios.get(`events/${event.m_iEventID}/pronos`)
                return data
            } catch (error) {
                console.log(error)
                return null
            }
        },
        enabled: !!event
    })

    const { data:prizes, refetch:refetchPrizes } = useQuery<PartnerEventPrize[] | null>({
        queryKey: [`prizes_${partnername}_${eventname.toLowerCase()}`],
        queryFn: async () => {
            try {
                const { data } = await axios.get(`partners/${partner.m_iPartnerID}/events/${event.m_iEventID}`)
                if (data.m_aPartnerEventPrizes) { 
                    let prizesArray = data.m_aPartnerEventPrizes 
                    prizesArray.sort((a,b)=>a.m_sPosition-b.m_sPosition)
                    return prizesArray
                }
                else return []
            } catch (error) {
                console.log(error)
                return null
            }
        },
        enabled: !!event && !!partner
    })


    useEffect(()=>{
        refetchEvent()
      }, [auth.status])

    useEffect(() => {
        // Check if the URL contains the hash #races
        const hash = window.location.hash;
        if (hash === "#races") {
          // Find the element with the id "races"
          const racesDiv = document.getElementById("races");
          if (racesDiv) {
            // Scroll to the element
            racesDiv.scrollIntoView({ behavior: "smooth" });
          }
        }
    }, []);

    function sortPronosByDate(pronos: Prono[]) {
        pronos.sort(function (a, b) {
            var dateA = new Date(a.m_dtStart);
            var dateB = new Date(b.m_dtStart);

            if (dateA < dateB) {
                return -1;
            }
            if (dateA > dateB) {
                return 1;
            }
            return 0;
        });
        return pronos
    }

    function getDateEvent() {
        let result = ""
        if(pronos){
            console.log(pronos)
            let startDate = isValid(new Date(pronos[0].m_dtStart)) ? format(new Date(pronos[0].m_dtStart), "d MMM") : ''
            let endDate = isValid(new Date(getFirstOpenProno(pronos, true).m_dtEnd)) ? format(new Date(getFirstOpenProno(pronos, true).m_dtEnd), "d MMM yyyy") : ''
            result += startDate;
            if(endDate !== ''){
                result += ` - ${endDate}`
            }
        }
        return result
    }

    function getFirstOpenProno(pronos: Prono[], last=false) {
        if(!last){
            let prono = sortPronosByDate(pronos).find(i=>i.m_bClosed===false) || pronos[0]
            return prono
        } else {
            let prono = sortPronosByDate(pronos).reverse().find(i=>i.m_bClosed===false) || pronos[0]
            return prono
        }
    }   

    function returnTranslationObject(translations:Translation[]) {
        return translations.find(i=>i.m_sLangCode===i18n.language.toUpperCase())
    }


    return (
        <>
        <div>
            {pronos && !getFirstOpenProno(pronos).m_bClosed && <Link to={`${pronos && getFirstOpenProno(pronos).m_iPronoID}`}><Button variant='tifo' className='fixed z-20 hidden right-4 bottom-4 sm:block'>{t("event.btn_start")}</Button></Link>}
            <div className='py-[88px] h-full relative min-h-[100vh] sm:min-h-[80vh] bg-tifo/50 flex justify-center items-center'>
                <div className='absolute z-[1] h-full w-full bg-black/60 backdrop-blur-md'></div>
                <img className='absolute object-cover w-full h-full' src={img} alt="Background" />
                <div className='flex flex-col gap-4 mx-4 relative z-[2] max-w-5xl w-full'>
                    
                    {partner && 
                    <div className="h-32 min-w-[128px] md:h-44 md:min-w-[176px] bg-white/50 p-4 rounded-sm w-fit">
                    {partner && <img className='h-full' src={partner.m_sLogo} alt="Logo" />}
                    {!partner && <div className='w-[100px] h-[100px]'></div>}
                    </div>
                    }

                    <p className='font-semibold text-white'>{getDateEvent()}</p>
                    <h1 className='text-3xl font-extrabold text-white sm:text-7xl'>{event && (getTranslationString(i18n.language.toUpperCase() as Language, event, "m_sNameNL") || event?.m_sNameNL)}</h1>
                    <p className='leading-relaxed text-white'>{event && (getTranslationString(i18n.language.toUpperCase() as Language, event, "m_sDescriptionNL") || event?.m_sDescriptionNL)}</p>
                    {pronos && <Link to={`${pronos && !getFirstOpenProno(pronos).m_bClosed ? getFirstOpenProno(pronos).m_iPronoID : ''}`} className='w-fit'><Button disabled={pronos && getFirstOpenProno(pronos).m_bClosed} size='lg' variant='tifo' className='w-fit'>
                        {pronos && !getFirstOpenProno(pronos).m_bClosed ? t("event.btn_start") : t("event.btn_ended")}
                    </Button></Link>}
                </div>
            </div>
            
            {(partnername && Array.isArray(prizes)) && 
            <section className='mt-[8vh] bg-tifo text-white py-10 relative flex justify-center'>
                <div className='relative flex items-end justify-between w-full max-w-5xl gap-10 mx-4'>
                    <div className='w-full h-full max-w-5xl mx-auto'>
                        <h3 className={`${getCycloPartnerConfig(auth.data.m_oPartner.m_iPartnerID).event.podium_prizes && 'text-center'} text-xl font-semibold`}>{t("event.title_prizes")}</h3>

                        {getCycloPartnerConfig(auth.data.m_oPartner.m_iPartnerID).event.podium_prizes
                        ?
                        <div className='flex flex-col w-full mt-10'>
                            <Prizes prizes={prizes}/>
                        </div>
                        :
                        <div className='flex flex-col gap-4 mt-4'>
                        {groupPrizes(prizes) && Object.keys(groupPrizes(prizes)) && Object.keys(groupPrizes(prizes)).map((key, ind)=>{
                            return (
                                <div key={ind}>
                                    <p className='mb-2 font-semibold underline'>{key}</p>
                                    <ul className='flex flex-col gap-2'>
                                    {groupPrizes(prizes)[key].map((prize, index)=>{
                                        if(prize.m_oPrize.m_sImageUrl!==''){
                                            return (
                                                <HoverCard openDelay={100} closeDelay={0} key={index}>
                                                    <HoverCardTrigger asChild><li className='ml-2 w-fit'><span className='font-semibold'>{t("event.price")} {prize.m_sPosition}.</span> {returnTranslationObject(prize.m_oPrize.m_aTranslations)?.m_sNameTransl || prize.m_sPrize}</li></HoverCardTrigger>
                                                    <HoverCardContent className='flex items-center justify-center'>
                                                        <img className='max-h-[150px]' src={prize.m_oPrize.m_sImageUrl} alt="" />
                                                    </HoverCardContent>
                                                </HoverCard>
                                            )
                                        }
                                        return (
                                            <li key={index} className='ml-2'><span className='font-semibold'>{t("event.place")} {prize.m_sPosition}.</span> {returnTranslationObject(prize.m_oPrize.m_aTranslations)?.m_sNameTransl || prize.m_sPrize}</li>
                                        )
                                    })}
                                    </ul>
                                </div>
                            )
                        })}
                        </div>
                        }

                        {prizes && prizes.length===0 && <p>{t("event.no_prizes")}</p>}
                    </div>
                    
                    {!getCycloPartnerConfig(auth.data.m_oPartner.m_iPartnerID).event.podium_prizes && 
                    <>
                    {(prizes && prizes[0] && prizes[0].m_oPrize.m_sImageUrl!=='')
                    ? <div className='bottom-0 right-0 p-4 mx-auto bg-white border-8 shadow-lg md:translate-y-1/2 w-fit border-tifo'><img className='max-h-[250px]' src={prizes[0].m_oPrize.m_sImageUrl} alt="Gifts" /></div>
                    : <img className='mx-auto right-0 bottom-0 md:translate-y-1/2 max-h-[300px] w-fit' src={gifts} alt="Gifts" />
                    }
                    </>
                    }
                    
                </div>
            </section>
            }
  
            <div className='mt-20'>
                <div className='flex justify-center mx-4'>
                    <h2 className='w-full max-w-5xl text-5xl font-extrabold'>{t("event.title_pronos")}</h2>
                </div>
                
                <div className='mt-12'>
                    <Slider pronos={pronos}/>
                </div>
            </div>
            
            
            <div className='pt-20 mx-4' id="races">
                <div className='max-w-5xl mx-auto border rounded-sm'>
                    <Table>
                        <TableHeader>
                            <TableRow className='bg-neutral-200 hover:bg-neutral-200'>
                                <TableHead className='px-2 text-sm font-medium sm:px-4 text-neutral-600 whitespace-nowrap'>{t("event.ride")}</TableHead>
                                <TableHead className='px-2 text-sm font-medium sm:px-4 text-neutral-600 whitespace-nowrap'>{t("event.date")}</TableHead>
                                <TableHead className='px-2 text-sm font-medium sm:px-4 text-neutral-600 whitespace-nowrap'>{t("event.start_finish")}</TableHead>
                                <TableHead className='px-2 text-sm font-medium sm:px-4 text-neutral-600 whitespace-nowrap'>{t("event.type_ride")}</TableHead>
                                <TableHead className='px-2 text-sm font-medium sm:px-4 text-neutral-600 whitespace-nowrap'>{t("event.amount_km")}</TableHead>
                                <TableHead className='px-2 text-sm font-medium sm:px-4 text-neutral-600 whitespace-nowrap'>{t("event.winner")}</TableHead>
                                {auth.data && <TableHead className='px-2 text-sm font-medium sm:px-4 text-neutral-600 whitespace-nowrap'>{t("event.points_win")}</TableHead>}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                                {isInterface(pronos, {} as Prono[]) && sortPronosByDate(pronos).map((prono, index) => {
                                return (
                                    <Row prono={prono} index={index} key={index}/>
                                )})}
                                {!isInterface(pronos, {} as Prono[]) && 
                                <>{Array(5).fill("").map((_,i)=>(
                                    <TableRow key={i}>
                                        <TableCell className='p-0' colSpan={99}><Skeleton className='w-full h-14'/></TableCell>
                                    </TableRow>
                                    ))}  
                                </>}
                        </TableBody>
                    </Table>
                </div>
            </div>
            
            {auth.data ? 
            <div className='py-10 mt-20 bg-tifo'>
                <div className='mx-4'>
                <div className='flex flex-col items-center max-w-5xl gap-8 mx-auto'>
                    <h2 className='text-3xl font-extrabold text-white'>{t("event.ranking")}</h2>
                    
                    {pronos && pronos.some(i=>i.m_bClosed) ? 
                    <>
                    <div className='grid w-full gap-8 md:grid-cols-3 md:gap-4'>
                        <div className='p-2 bg-white/10'>
                            <p className='font-semibold text-white uppercase'>{t("event.rank")} <span className='text-tifo-light'>{t("event.company")}</span></p>
                            <SmallRankingsTable eventid={event && event.m_iEventID} partnerid={auth.data.m_oPartner.m_iPartnerID} view='partner' className='mt-4'/>
                        </div>
                        <div className='p-2 bg-white/10'>
                            <p className='font-semibold text-white uppercase'>Klassement <span className='text-tifo-light'>groepen</span></p>
                            <SmallRankingsTable eventid={event && event.m_iEventID} partnerid={auth.data.m_oPartner.m_iPartnerID} className='mt-4' view='groups'/>
                        </div>
                        <div className='p-2 bg-white/10'>
                            <p className='font-semibold text-white uppercase'>{t("event.rank")} <span className='text-tifo-light'>{t("event.group")}</span></p>
                            <SmallRankingsTable eventid={event && event.m_iEventID} departmentid={auth.data.m_oDepartment.m_iDepartmentID} view='department' className='mt-4'/>
                        </div>
                    </div>

                    <Link to="/klassementen" className='underline uppercase text-tifo-light'>{t("event.more")}</Link>
                    </>
                    :
                    <>
                    <div className='italic text-white/60'>
                        <p>{t("event.no_ranking")}</p>
                    </div>
                    </>
                    }
                </div>
                </div>
            </div>
            : <div className='pt-20'></div>}
        </div>
        <Footer className='bg-tifo/90'/>
        </>
    )
}

export default Event
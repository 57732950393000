import { cn } from '../../../lib/utils'
import React, { HTMLAttributes, useEffect, useState } from 'react'
import { MoveLeft, MoveRight } from 'lucide-react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  pronos?: Prono[] | null
  prono?: Prono | null
}

const Navigation : React.FC<Props & HTMLAttributes<HTMLDivElement>> = ({prono, pronos, className}) => {
  const {t} = useTranslation()
  const {eventname, pronoid, partnername} = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const [hasPrev, setHasPrev] = useState(false)
  const [hasNext, setHasNext] = useState(false)

  const [prevId, setPrevId] = useState(0)
  const [nextId, setNextId] = useState(0)

  useEffect(()=>{
    if(prono && pronos){
      const pronoIndex = pronos.findIndex(i=>i.m_iPronoID===prono.m_iPronoID)
      if(pronoIndex === 0){
        //First prono
        setHasPrev(false)
      } else {
        setHasPrev(true)
        if(pronos[pronoIndex-1]){ setPrevId(pronos[pronoIndex-1].m_iPronoID) }
        else { console.log("error"); setPrevId(parseFloat(pronoid || '0')) }
      }

      if(pronoIndex+1 === pronos.length){
        setHasNext(false)
      } else {
        setHasNext(true)
        setNextId(pronos[pronoIndex+1].m_iPronoID)
      }
    }
  }, [prono, pronos])

  
  // const disableNext = pronoIndex > -1 ? (pronoIndex+1) === pronos?.length : true
  // const disablePrev = pronos ? pronos[0].m_iPronoID===prono?.m_iPronoID : true

  return (
    <div className={cn('flex items-center sm:gap-8 bg-black/40 w-fit h-10 rounded-full p-1 backdrop-blur-sm', className)}>
        <button onClick={()=>{navigate(`/${partnername ? `${partnername}/` : 'event/'}${eventname}/${prevId}`, {replace:true})}} disabled={!hasPrev} className='disabled:opacity-25 flex items-center gap-2 text-white text-sm font-medium h-full rounded-full px-2 hover:bg-white/40 duration-200 whitespace-nowrap'><MoveLeft className='w-4'/>{t("prono.o_tabs.previous")}</button>
        <Link to={`/${partnername ? `${partnername}/` : 'event/'}${eventname}#races`} className='hidden sm:flex items-center gap-2 text-white text-sm font-medium h-full rounded-full px-2 hover:bg-white/40 duration-200 whitespace-nowrap'>{t("prono.o_tabs.home")}</Link>
        <button onClick={()=>{navigate(`/${partnername ? `${partnername}/` : 'event/'}${eventname}/${nextId}`, {replace:true})}} disabled={!hasNext} className='disabled:opacity-25 flex items-center gap-2 text-white text-sm font-medium h-full rounded-full px-2 hover:bg-white/40 duration-200 whitespace-nowrap'>{t("prono.o_tabs.next")}<MoveRight className='w-4'/></button>
    </div>
  )
}

export default Navigation
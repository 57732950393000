import { combineReducers } from 'redux'

import loginPopupReducer, { userdataReducer } from './loginPopupReducer';


const reducers = combineReducers({
    loginPopup: loginPopupReducer,
    userdata: userdataReducer
})

export default reducers; 
import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Login from './Login/Login'

const Management = () => {
  return (
    <Routes>
        <Route path="" element={<Login />}/>
        <Route path="users" element={<>Users</>}/>
    </Routes>
  )
}

export default Management
import React from 'react'
import { Outlet, useLocation, useNavigate, useOutlet } from "react-router-dom";
import Login from '../../components/login/index'

const Main = () => {
    const outlet = useOutlet();

    return (
        <>
        <Login />
        <div className='h-full'>
            <div className='h-full' key={useLocation().pathname}>
                {outlet}
            </div>
        </div>
        </>
    )
}

export default Main
import React from 'react'
import Footer from '../../layouts/Footer'
import { useTranslation } from 'react-i18next'

const Policy = () => {
    const { t } = useTranslation()

    const h1 = 'mt-16 w-fit text-3xl font-semibold mb-6 text-tifo relative after:content-[""] after:absolute after:w-full after:h-[3px] after:bg-tifo-light after:bottom-0 after:left-0 after:translate-y-1'
    const h2 = 'text-xl font-medium mb-4 mt-8'
    const h3 = 'text-lg font-medium mt-6 mb-3'
    const list = 'list-disc flex flex-col gap-2 ml-4 my-4 text-neutral-500 text-md'
    const bold = 'font-bold mt-6 mb-3'

  return (
    <>
        <div className='w-full h-[88px] bg-tifo'></div>
        
        <div className='flex justify-center min-h-screen'>
            <div className='max-w-5xl w-full mx-4 pb-16'>
                <h1 className={h1}>1. COOKIES</h1>
                <p>{t("cookies.general")}</p>
                <h3 className={h3}>{t("cookies.func")}</h3>
                <p>{t("cookies.func_desc")}</p>
                <h3 className={h3}>{t("cookies.stats")}</h3>
                <p>{t("cookies.stats_desc")}</p>
            </div>
        </div>

        <Footer />
    </>
  )
}

export default Policy
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json'
import translationNL from './locales/nl/translation.json'
import translationFR from './locales/fr/translation.json'
import translationDE from './locales/de/translation.json'

const resources = {
    en: {
        translation: translationEN
    },
    nl: {
        translation: translationNL
    },
    fr: {
        translation: translationFR
    },
    de: {
        translation: translationDE
    }
}

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('i18nextLng') || 'nl',
    debug: true,
    detection: {
        order: ['queryString', 'cookie'],
        cache: ['cookie']
    },
    interpolation: {
        escapeValue: false
    }
})

export default i18n
import React, { HTMLAttributes } from 'react'
import logo from '../assets/logo/logo_white.svg'
import { Link } from 'react-router-dom'
import { Facebook, Globe, Instagram, Linkedin, Youtube } from 'lucide-react'
import { format } from 'date-fns'
import useAuth from '../hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../components/ui/select'
import { cn } from '../lib/utils'

const Footer = ({className}:HTMLAttributes<HTMLDivElement>) => {
  const {t, i18n} = useTranslation()
  const {auth} = useAuth()
  return (
    <div className={cn('bg-tifo text-white py-16 flex justify-center', className)}>
        <div className='max-w-5xl w-full mx-4 flex justify-between flex-wrap gap-4'>
          {/* Left */}
          <div className='flex flex-col gap-2'>
            <img className='h-8' src={logo} alt="logo tifogame"/>
            <p className='text-xs opacity-50'>Copyright {format(new Date, "yyyy")}</p>
          </div>

          {/* Right */}
          <div className='flex flex-col gap-4 w-full sm:w-3/5'>
            {/* Pages */}
            <div className='grid grid-cols-1 sm:grid-cols-3 gap-2 text-sm'>
              <Link to="/" className='grid grid-cols-[2px,1fr] gap-1 opacity-75 hover:opacity-100'><div className='w-[2px] h-full rounded-full bg-tifo-light'></div><p>{t("footer.home")}</p></Link>
              {auth.status==='authenticated' && <Link to="/klassementen" className='grid grid-cols-[2px,1fr] gap-1 opacity-75 hover:opacity-100'><div className='w-[2px] h-full rounded-full bg-tifo-light'></div><p>{t("footer.ranking")}</p></Link>}
              <Link to="/wedstrijdreglement" className='grid grid-cols-[2px,1fr] gap-1 opacity-75 hover:opacity-100'><div className='w-[2px] h-full rounded-full bg-tifo-light'></div><p>{t("footer.rules")}</p></Link>
              <Link to="/cookies" className='grid grid-cols-[2px,1fr] gap-1 opacity-75 hover:opacity-100'><div className='w-[2px] h-full rounded-full bg-tifo-light'></div><p>Cookies</p></Link>

            </div>

            <div className='h-px w-full bg-white/40 rounded-full'></div>
            {/* Socials */}
            <div className='flex justify-between gap-4'>
              
              <Select onValueChange={(v)=>{ i18n.changeLanguage(v.toLowerCase()) }} value={i18n.language.toLowerCase()}>
                <SelectTrigger className='w-fit bg-transparent rounded-md border-tifo-light'>
                  <SelectValue placeholder="Selecteer een taal..."/>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="nl">Nederlands</SelectItem>
                    <SelectItem value="en">English</SelectItem>
                    <SelectItem value="fr">Francais</SelectItem>
                    <SelectItem value="de">Deutsch</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
              
              <div className='flex gap-4 items-center'>
                <Link to="https://www.linkedin.com/company/tifogroup/"><Linkedin className='text-tifo-light w-5'/></Link>
                <Link to="https://www.youtube.com/channel/UC5-n6YS8G6qQ5WMQB7VUOVg"><Youtube className='text-tifo-light w-5'/></Link>
                <Link to="https://www.facebook.com/tifogame/"><Facebook className='text-tifo-light w-5'/></Link>
                <Link to="https://www.instagram.com/tifogame/"><Instagram className='text-tifo-light w-5'/></Link>
                <Link to="https://www.tifogame.be/nl"><Globe className='text-tifo-light w-5'/></Link>
              </div>
            </div>
          </div>
          
        </div>
    </div>
  )
}

export default Footer
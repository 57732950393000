import React, { useRef, useEffect, useState } from 'react'
import { Check, ChevronsRight, Eye, Flag, Lock } from 'lucide-react'
import { isBefore, format, isValid } from 'date-fns';
import { Link, useParams, useSearchParams } from 'react-router-dom';  
import { convertToUrl } from '../../../lib/utils';
import { useQuery } from '@tanstack/react-query';
import axios from '../../../lib/axios';
import useAuth from '../../../hooks/useAuth';
import { Skeleton } from '../../../components/ui/skeleton';
import { useTranslation } from 'react-i18next';
import { getTranslationString } from '../../../lib/translation';

const cards = [
    {
        title: 'Etappe 1',
        description: 'Bilbao > Bilbao',
        date: '2022-07-19',
        image: 'https://img.aso.fr/core_app/img-cycling-tdf-jpg/header-podium-vingo/52230/0:0,2000:890-2000-0-60/06700'
    },
    {
        title: 'Etappe 2',
        description: 'Bilbao > Bilbao',
        date: '2022-07-20',
        image: 'https://img.aso.fr/core_app/img-cycling-tdf-jpg/header-podium-vingo/52230/0:0,2000:890-2000-0-60/06700'
    },
    {
        title: 'Etappe 3',
        description: 'Bilbao > Bilbao',
        date: '2023-05-21',
        image: 'https://img.aso.fr/core_app/img-cycling-tdf-jpg/header-podium-vingo/52230/0:0,2000:890-2000-0-60/06700'
    },
    {
        title: 'Etappe 4',
        description: 'Bilbao > Bilbao',
        date: '2023-06-22',
        image: 'https://img.aso.fr/core_app/img-cycling-tdf-jpg/paysage/16437/328:0,856:801-660-0-30/f9846'
    },
    {
        title: 'Etappe 5',
        description: 'Bilbao > Bilbao',
        date: '2023-10-23',
        image: 'https://img.aso.fr/core_app/img-cycling-tdf-jpg/card-et3/47814/0:0,660:1000-660-0-30/cbe30'
    },
    {
        title: 'Etappe 6',
        description: 'Bilbao > Bilbao',
        date: '2023-10-19',
        image: 'https://img.aso.fr/core_app/img-cycling-tdf-jpg/card-vainqueur/47542/0:0,660:1000-660-0-30/6e467'
    },{
        title: 'Etappe 7',
        description: 'Bilbao > Bilbao',
        date: '2023-11-19',
        image: 'https://img.aso.fr/core_app/img-cycling-tdf-jpg/card-et3/47814/0:0,660:1000-660-0-30/cbe30'
    }
]

type Props = {
  pronos?: Prono[]
}

const PronoCard = ({prono}:{prono:Prono}) => {
  const {t, i18n} = useTranslation()
  const {auth} = useAuth()
  const { data:picked } = useQuery({
    queryKey: [`prevPicked_${prono.m_iPronoID}`],
    queryFn: async () => {
      try {
        const {data} = await axios.get(`user-prono-results?pronoid=${prono.m_iPronoID}&userid=${auth.data.m_iUserID}`)
        let results = data.filter(i=>parseFloat(i.m_sPosition)>0)
        if(results.length>0){return results}
        return null
      } catch (error) {
        return null
      }
    }
  })

  return (
    <Link to={`${prono.m_iPronoID}`}>
      <div
        className={`${(prono.m_bClosed) ? 'bg-neutral-200 grayscale' : 'bg-tifo'} h-96 snap-center shrink-0 p-4 w-64 rounded-md relative flex items-end group`}
      > 
        <div className={`${!prono.m_bActive ? 'bg-neutral-200 text-neutral-700' : 'bg-tifo-light text-tifo'} shadow-sm absolute z-10 top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 font-semibold px-3 py-1`}>
          {isValid(new Date(prono.m_dtStart)) ? format(new Date(prono.m_dtStart), "d MMM yyyy") : t("event.o_slider.unknown")}
        </div>

        {(prono.m_bClosed) && 
        <div className='absolute text-white z-[2] bg-gradient-to-b from-black to-transparent w-full py-10 font-semibold rounded-t-md flex justify-center items-center text-sm uppercase left-0 top-0'>
          <p className='flex items-center gap-2 duration-200 -translate-x-4 group-hover:translate-x-0'><Flag className='w-4 duration-500 opacity-0 group-hover:fill-white group-hover:opacity-100 fill-transparent'/>{t("event.o_slider.view_results")}</p>
        </div>
        }

        {(picked && !prono.m_bClosed) &&
        <div className='absolute text-white z-[2] bg-gradient-to-b from-black to-transparent w-full py-10 font-semibold rounded-t-md flex justify-center items-center text-sm uppercase left-0 top-0'>
          <p className='flex items-center gap-2 duration-200'><Check className='w-4 fill-transparent'/>{t("event.o_slider.filled_in")}</p>
        </div>
        }

        <div className='absolute left-0 top-0 w-full h-full bg-black/50 z-[1] rounded-md duration-300 backdrop-blur-sm hover:backdrop-blur-none'></div>
        <img className='absolute top-0 left-0 object-cover h-full rounded-md' src={prono.m_sImageUrl ? prono.m_sImageUrl : 'https://images.pexels.com/photos/248547/pexels-photo-248547.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'} alt={`${prono.m_oPronoType.m_sDescriptionNL} ${prono.m_sDescriptionNL}`} />

        <button className='w-10 h-10 bg-white/20 rounded-full z-[1] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white flex justify-center items-center hover:bg-white/40'>
          {(prono.m_bClosed)
            ? <Lock className='w-5'/>
            : <ChevronsRight className='w-5'/>
          }
          
        </button>

        <div className={`${prono.m_bClosed && 'opacity-50'} z-[1]`}>
          <p className='text-2xl font-bold text-white uppercase'>{getTranslationString(i18n.language.toUpperCase() as Language, prono.m_oPronoType, "m_sNameNL") || prono.m_oPronoType.m_sNameNL}</p>
          <p className='text-white uppercase line-clamp-4'>{getTranslationString(i18n.language.toUpperCase() as Language, prono.m_oPronoType, "m_sDescriptionNL") || prono.m_sDescriptionNL}</p>
        </div>          
      </div>
    </Link>
  )
}

const Slider : React.FC<Props> = ({pronos}) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        // Function to calculate the card with the closest date to today
        if(!pronos) { return; }
        const findClosestDate = () => {
          const today = new Date();
          let closestDateIndex = 0;
          let closestDateDiff = Math.abs(today.getTime() - new Date(pronos[0].m_dtStart).getTime());
    
          pronos.forEach((prono, index) => {
            const dateDiff = Math.abs(today.getTime() - new Date(prono.m_dtStart).getTime());
            if (dateDiff < closestDateDiff) {
              closestDateDiff = dateDiff;
              closestDateIndex = index;
            }
          });
    
          return closestDateIndex;
        };
    
        // Scroll to the card with the closest date on initial render
        const closestDateIndex = findClosestDate();
        const closestCard = scrollContainerRef.current?.children[closestDateIndex] as HTMLDivElement;
        if (closestCard) {
          const containerWidth = scrollContainerRef.current?.offsetWidth || 0;
          const cardWidth = closestCard.offsetWidth;
          let scrollOffset = closestCard.offsetLeft - (containerWidth - cardWidth) / 2;


          const amountChildren = scrollContainerRef.current?.children.length || 0;
          const amountGap = amountChildren * 24

          scrollOffset += amountGap

          scrollContainerRef.current?.scrollTo({ left: scrollOffset, behavior: 'smooth' });
        }
    }, [pronos]);

    return (
        <div
            ref={scrollContainerRef}
          className={`${(pronos && pronos.length) > 5 ? 'max-w-7xl' : 'max-w-5xl'} relative w-full mx-auto flex gap-6 snap-x overflow-x-auto py-4 px-4 sm:px-0`}
        >
          {pronos && pronos.map((prono, index) => (
            <PronoCard prono={prono} key={index}/>
          ))}
          {!pronos && Array(5).fill('').map((_,i)=>(
            <Skeleton key={i} className={`h-96 bg-neutral-200 snap-center shrink-0 p-4 w-64 rounded-md relative flex items-end group`}></Skeleton>
          ))}
        </div>
    );
}

export default Slider
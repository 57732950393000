"use client"
 
import * as React from "react"
import { Check, ChevronsUpDown } from "lucide-react"

import { cn } from "../../lib/utils"
import { Button } from "./button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./popover"


type Item = {
    value: string | number;
    label: string | number;
}

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    placeholder?: string;
    input_placeholder?: string;
    nothing_found?: string;
    options: Item[];
    onValueChange?: (value:Item) => void;
    selectedValue?: string | 'clear';
}
 
export const Combobox : React.FC<Props> = ({placeholder='Select...', input_placeholder='Search...', nothing_found='No items found', options, onValueChange, selectedValue, className, ...props}) => {
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState('')

  const [records, setrecords] = React.useState(20);
  const [hasMore, setHasMore] = React.useState(true);

  React.useEffect(() => {
    if(selectedValue){
      if(selectedValue === 'clear'){
        setValue('')
        return;
      }
      setValue(selectedValue)
    }
  }, [selectedValue])
  
  const loadMore = () => {
    if (records === options.length) {
      setHasMore(false);
    } else {
      setTimeout(() => {
        setrecords(prev => prev + 20);
      }, 100);
    }
  };

  const showItems = (options:Item[]) => {
    var items = [];
    for (var i = 0; i < records; i++) {
      options[i] && items.push(
        <CommandItem
          key={options[i].value}
          onSelect={(currentValue) => {
            setValue(currentValue === options[i].value.toString() ? "" : options[i].value.toString())
            onValueChange && onValueChange(options[i])
            setOpen(false)
          }}
        >
          <Check
            className={cn(
              "mr-2 h-4 w-4",
              value === options[i].value.toString() ? "opacity-100" : "opacity-0"
            )}
          />
          {options[i].label}
        </CommandItem>
      );
    }
    return items;
  };
 
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          {...props}
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("w-full justify-between", className)}
        >
          {value
            ? options.find((framework) => framework.value.toString().toLowerCase() === value)?.label
            : placeholder}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command>
          <CommandInput placeholder={input_placeholder} />
          <CommandEmpty>{nothing_found}</CommandEmpty>
          <CommandGroup className='overflow-y-auto max-h-60'>

            {/* <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={hasMore}
              loader={<></>}  
              useWindow={false}
            >
              {showItems(options)}
            </InfiniteScroll> */}

            {options.map((framework) => (
              <CommandItem
                key={framework.value}
                onSelect={(currentValue) => {
                  setValue(currentValue === framework.value.toString() ? "" : framework.value.toString())
                  onValueChange && onValueChange(framework)
                  setOpen(false)
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value === framework.value.toString() ? "opacity-100" : "opacity-0"
                  )}
                />
                {framework.label}
              </CommandItem>
            ))}
            
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
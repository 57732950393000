import { Outlet } from "react-router-dom";
import Navbar from "../layouts/Navbar";

const Layout : React.FC = () => {
    return (
        <>
        <main className="flex flex-col">
            <Navbar />
            <Outlet />
        </main>
        </>
    )
}

export default Layout;
export function filterObjectsByValue(arr: object[], searchValue: string) {
    return arr.filter(obj => checkNested(obj, searchValue));
}

function checkNested(obj: Record<string, any>, searchValue: string): boolean {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === "object" && obj[key] !== null) {
                if (checkNested(obj[key], searchValue)) {
                    return true;
                }
            } else if (typeof obj[key] === 'string' && obj[key].toString().toLowerCase().includes(searchValue.toLowerCase()) || typeof obj[key] === 'number' && obj[key].toString().toLowerCase().includes(searchValue.toLowerCase())) {
                return true;
            }
        }
    }
    return false;
}

export function filterArrayByValue(arr: object[], searchValue: string, keysToFilterOn?: string[]) {
    return arr.filter(obj => checkNestedArray(obj, searchValue, keysToFilterOn));
}

function checkNestedArray(obj: Record<string, any>, searchValue: string, keysToFilterOn?: string[]): boolean {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (keysToFilterOn && keysToFilterOn.length > 0 && !keysToFilterOn.includes(key)) {
                continue; // Skip this key if filtering on specific keys and it's not in the list
            }
            if (typeof obj[key] === "object" && obj[key] !== null) {
                if (checkNestedArray(obj[key], searchValue, keysToFilterOn)) {
                    return true;
                }
            } else if (typeof obj[key] === 'string' && obj[key].toString().toLowerCase().includes(searchValue.toLowerCase()) || typeof obj[key] === 'number' && obj[key].toString().toLowerCase().includes(searchValue.toLowerCase())) {
                return true;
            }
        }
    }
    return false;
}
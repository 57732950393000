import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Profile from './Profile'

const ProfileMain = () => {
  return (
    <Routes>
        <Route path="" element={<Profile />}/>
    </Routes>
  )
}

export default ProfileMain
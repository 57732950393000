import React, { useEffect, useState } from 'react'
import Footer from '../../../layouts/Footer'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import axios from '../../../lib/axios'
import { Label } from '../../../components/ui/label'
import { Combobox } from '../../../components/ui/combobox'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/ui/table'
import { convertToUrl } from '../../../lib/utils'
import { Bike, ChevronRight, Flag } from 'lucide-react'
import toast, { Toaster } from 'react-hot-toast'
import { eventsURL } from '../../../lib/fetch'
import useAuth from '../../../hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { getTranslationString } from '../../../lib/translation'
import { AxiosError } from 'axios'

const Department = () => {
    const {auth} = useAuth()
    const {t, i18n} = useTranslation()
    const {partnername, departmentname} = useParams()
    const navigate = useNavigate()

    const { data:partner } = useQuery<Partner | null>({
        queryKey: [`partner_${partnername}`],
        queryFn: async () => {
        try {
            const {data} = await axios.get(`partner/${partnername}`)
            if(Array.isArray(data)){throw new Error()}
            if(data){ return data }
            else { throw new Error()}
        } catch (error) {
            navigate('/')
            return null
        }
        }
    })

    const { data:events } = useQuery<PartnerEvent[]>({
        queryKey: [`events_${partnername}`],
        queryFn: async () => {
        try {
            const {data} = await axios.get(`partners/${partner.m_iPartnerID}/${eventsURL()}`)
            let filteredData = data.filter(i=>i.m_bActive===true)
            if(Array.isArray(filteredData)){ setselected_competition(filteredData[0].m_iEventID.toString()); return filteredData }
            else { throw new Error()}
        } catch (error) {
            console.log(error)
            return null
        }
        },
        enabled: !!partner
    })

    const { data:department } = useQuery<PartnerDepartment | null>({
      queryKey: [`department_${departmentname}`],
      queryFn: async () => {
        try {
          const {data} = await axios.get(`partner/${partnername}/${departmentname}`)
          console.log(data)
          if(Array.isArray(data)){throw new Error('multiple departments found')}
          return data
        } catch (error) {
          console.log(error)
          navigate(`/${partnername}`)
          return null;
        }
      },
      enabled: !!partner
    })

    const [selected_competition, setselected_competition] = useState<string>();
    const [competition_results, setcompetition_results] = useState<EventRanking[] | null>(null);

    useEffect(()=>{
      if(selected_competition && department){
        getEventRankings()
      }
    }, [selected_competition, department])

    const getEventRankings = async () => {
      try {
        //const {data} = await axios.get(`partners/${partner.m_iPartnerID}/events/${selected_competition}/ranking&departmentid=${department?.m_iDepartmentID}`)
        
        const {data} = await axios.get(`ranking?eventid=${selected_competition}&departmentid=${department?.m_iDepartmentID}`)
        if(!Array.isArray(data)){throw new Error('no ranking array')}
        setcompetition_results(data)
      } catch (error) {
        if(error instanceof AxiosError){ 
          if(error.response.status === 400){
            if(error.response.data.toLowerCase().includes('partner id')){
              toast.error(`${t("error.400_ranking_get")}`, {duration:5000}); return;
            }
            if(error.response.data.toLowerCase().includes('event id')){
              toast.error(`${t("error.400_ranking_get-2")}`, {duration:5000}); return;
            }
          }
          if(error.response.status === 401){
            toast.error(`${t("error.401_ranking_get")}`, {duration:5000}); return;
          }
          if(error.response.status === 404){
            toast.error(`${t("error.404_ranking_get")}`, {duration:5000}); return;
          }
        }

        toast.error(t("partner.o_department.no_ranking"), {id:`ranking_${selected_competition}`})
        console.log(error)
      }
    }

    function returnTranslationObject(translations:Translation[]) {
      return translations.find(i=>i.m_sLangCode===i18n.language.toUpperCase())
    }

  return (
    <>
    <Toaster />
    <div className='flex flex-col items-center pt-32 pb-16 relative bg-tifo'>
      <div className='max-w-5xl w-full mx-4 relative z-[2] text-white'>
        <div className='mt-[20vh] md:mt-[60vh] flex-col flex items-start gap-10 mx-4 lg:mx-0 pb-16'>
          <div className="h-16 w-auto min-w-16 bg-white/50 p-2 rounded-sm">{partner && partner.m_sLogo ? <img className='w-auto h-full object-cover' src={partner.m_sLogo} alt="Logo"/> : <></>}</div>
          <div>
            <h1 className='text-3xl sm:text-6xl font-bold mt-4'>{department?.m_aTranslations ? (returnTranslationObject(department.m_aTranslations)?.m_sNameTransl || department?.m_sName) : department?.m_sName}</h1>
            <p className='text-sm sm:text-lg mt-6 sm:mt-16 max-w-3xl leading-loose font-medium'>
              {department?.m_aTranslations && (returnTranslationObject(department.m_aTranslations)?.m_sDescTransl)}
            </p>
          </div>
        </div>        
      </div>
    </div>

    <div className='absolute w-full h-fit top-0 left-0'>
        <div className='relative h-full'>
          <div className='w-full h-full absolute top-0 left-0 bg-gradient-to-b from-tifo/20 to-tifo z-[1]'></div>
          <div className='w-full h-full absolute top-0 left-0'><img className='h-full w-full object-cover' alt="department image" src={department && department.m_sImageUrl ? department.m_sImageUrl : "https://images.pexels.com/photos/248547/pexels-photo-248547.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"}/></div>
        </div>
    </div>

    <div className='py-16 flex justify-center bg-slate-50'>
      <div className='max-w-5xl w-full mx-4'>
        <h2 className='text-4xl font-bold max-w-4xl leading-tight'>{t("partner.o_department.ranking")}</h2>

        <div className='flex flex-col gap-8 bg-white rounded-lg shadow-lg p-8 my-8'>
          <div className='flex flex-col gap-1 w-fit'>
              <Label className='ml-2 font-semibold text-neutral-600 uppercase' htmlFor='competition'>{t("partner.o_department.competition")}</Label>
              <Combobox selectedValue={selected_competition} onValueChange={(v)=>{setselected_competition(v.value.toString())}} options={events ? events.map((v)=>({label:v.m_oEvent.m_sNameNL, value:v.m_iEventID})) : []} className='min-w-[250px] rounded-full' placeholder={t("partner.o_department.competition_placeholder")} input_placeholder={t("partner.o_department.search_placeholder")} id='competition'/>
          </div>

          {(selected_competition && competition_results) &&
            <div className='border rounded-md overflow-auto'>
                <Table className=''>
                    <TableHeader className='bg-slate-200'>
                        <TableRow className='bg-slate-200'>
                            <TableHead className='py-4'>{t("partner.o_department.position")}</TableHead>
                            <TableHead className='py-4'>{t("partner.o_department.name")}</TableHead>
                            <TableHead className='py-4'>{t("partner.o_department.points")}</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {competition_results.map((rank,i)=>{
                            return (
                                <TableRow className={`${i===0&&'font-semibold'} ${rank.m_iUserID === auth.data.m_iUserID && 'text-green-600 font-semibold'} bg-tifo-darker hover:bg-tifo-light/10`} key={i}>
                                    <TableCell className='py-6'>{i+1}</TableCell>
                                    <TableCell className='py-6 flex items-center gap-1'>{rank.m_iUserID === auth.data.m_iUserID && <ChevronRight className='w-3'/>} {rank.m_sUser} {rank.m_iUserID === auth.data.m_iUserID && ` (${t("partner.o_department.you")})`}</TableCell>
                                    <TableCell className='py-6'>{rank.m_iPoints}</TableCell>
                                </TableRow>
                            )
                        })}              
                    </TableBody>
                </Table>
            </div>
          }
        </div>

        

      </div>
    </div>

    <div className='bg-white w-full pt-16 pb-32 flex justify-center'>
        <div className='w-full max-w-5xl mx-4'>
          <h2 className='text-4xl font-bold w-fit'>{t("partner.o_department.events")}</h2>

          <div className='grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4 mt-10 '>
            {events && events.map((event, index) => {
              return (
                <Link to={`/${partnername}/${getTranslationString(i18n.language.toUpperCase() as Language, event.m_oEvent, "m_sUrlNL") || event.m_oEvent.m_sUrlNL}`} key={index}>
                <div className='rounded-lg overflow-hidden shadow-lg flex flex-col justify-between w-fit h-full hover:scale-105 duration-200 hover:shadow-xl'>
                  <div className='w-min-content h-full min-h-[150px] max-h-[250px]'>
                    <img className={`${event.m_oEvent.m_bClosed && 'grayscale'} w-full h-full max-h-[250px] min-h-[150px] object-cover`} src={event.m_oEvent.m_sImageUrl || "https://img.aso.fr/core_app/img-cycling-prx-jpg/header-arrivee/4871/0:0,2000:690-2000-0-60/84728"} alt="" />
                  </div>
                  <div className='bg-white p-4 flex flex-col gap-2'>
                    <h4 className={`${event.m_oEvent.m_bClosed ? 'text-black/70' : 'text-green-700'} text-lg font-bold`}>{getTranslationString(i18n.language.toUpperCase() as Language, event.m_oEvent, "m_sNameNL") || event.m_oEvent.m_sNameNL}</h4>
                    <div className='relative'>
                      <p className='max-h-[50px] text-sm text-neutral-400 overflow-hidden duration-200'>{getTranslationString(i18n.language.toUpperCase() as Language, event.m_oEvent, "m_sDescriptionNL") || event.m_oEvent.m_sDescriptionNL}</p>
                      <div className='absolute bottom-0 w-full bg-gradient-to-t from-white/90 to-transparent h-6'></div>
                    </div>
                    <div className='flex justify-between items-center'>
                      <p className={`${!event.m_oEvent.m_bClosed && 'text-green-700 underline font-medium'} text-sm flex items-center gap-2`}>
                      {event.m_oEvent.m_bClosed ? <Flag className='w-4' strokeWidth={1.5}/> : <Bike className='w-4' strokeWidth={1.5}/> }
                      {event.m_oEvent.m_bClosed ? t("partner.expired") : t("partner.join")}</p>
                    </div>        
                  </div>
                </div>
                </Link>
              )
            })}
            
          </div>
        </div>
      </div>

    <Footer />
    </>
  )
}

export default Department
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select'
import Footer from '../../layouts/Footer'
import { useWindowSize } from '@uidotdev/usehooks'

const GameRules = () => {
    const { i18n } = useTranslation()
    const { width } = useWindowSize()

    const h1 = 'mt-16 w-fit text-3xl font-semibold mb-6 text-tifo relative after:content-[""] after:absolute after:w-full after:h-[3px] after:bg-tifo-light after:bottom-0 after:left-0 after:translate-y-1'
    const h2 = 'text-xl font-medium mb-4 mt-8'
    const h3 = 'text-lg font-medium mt-6 mb-3'
    const list = 'list-disc flex flex-col gap-2 ml-4 my-4 text-neutral-500 text-md'
    const bold = 'font-bold mt-6 mb-3'

    return (
    <>
        <div className='w-full h-[88px] bg-tifo'></div>
        
        {/* Nederlands */}
        {i18n.language === 'nl' && 
        <div className='flex justify-center'>
            <div className='max-w-5xl w-full mx-4 pb-16'>
                <h1 className={h1}>1. ALGEMEEN</h1>
                <h2 className={h2}>1.1 DEELNEMINGSVOORWAARDEN</h2>
                <ul className={list}>
                    <li>Elke meerderjarige natuurlijke persoon die kan meespelen met de voorspellingen aangeboden op <Link to="/" className='underline text-tifo-light'>tifogame.be</Link>.</li>
                    <li>Minderjarigen mogen aan de voorspellingen deelnemen op voorwaarde dat zij de voorafgaandelijke toestemming van hun ouders (of van de persoon die het ouderlijk gezag uitoefent) daarvoor gekregen hebben. Indien een minderjarige deelneemt, betekent dit dat hij/zij dergelijke toestemming verkregen heeft. Tifogroup BV behoudt zich het recht voor om op elk moment de schriftelijke bevestiging van deze toestemming op te vragen.</li>
                    <li>Een Deelnemer mag slechts over 1 account beschikken. Tifogroup BV kan de accounts van een Deelnemer verwijderen indien zij redelijke vermoedens heeft dat hij/zij meerdere accounts gebruikt. Tifogroup BV behoudt zich het recht voor om prijzen niet toe te kennen indien zij redelijke vermoedens heeft dat een Deelnemer vals speelt.</li>
                    <li>Een Deelnemer kan slechts eenmaal deelnemen aan eenzelfde voorspelling.</li>
                    <li>De voorspellingen die worden georganiseerd in samenwerking met de lokale besturen hebben als doel de lokale handelaars te ondersteunen. Er kan slechts worden deelgenomen indien de Deelnemer de code fysiek heeft ontvangen bij een desbetreffende handelaar EN woonachtig is in de gemeente/stad of in de buurtgemeente. Tifogroup BV behoudt zich het recht om Deelnemers te verwijderen indien er redelijke vermoedens zijn van valsspelen.</li>
                    <li>Voor interne wedstrijden georganiseerd door een onderneming geldt dat elke deelnemer op het platform, die in dienst is bij de onderneming in kwestie, recht heeft op het aangeboden prijzenpakket.</li>
                </ul>
                <h2 className={h2}>1.2 TIFOGAME CYCLING</h2>
                <p>Tifogame is een product van Tifogroup BV. Cycling.tifogame.be is een online platform waarop Deelnemers gratis voorspellingen kunnen plaatsen voor verschillende wielerwedstrijden.</p>
                <p className={bold}>Er is nooit een inzet in geld door een deelnemer</p>

                <h1 className={h1}>2. VOORSPELLINGEN</h1>
                <h2 className={h2}>2.1 TYPES VOORSPELLINGEN</h2>
                <p className='mt-4 font-medium mb-2'>Tifogame hanteert één types voorspellingen:</p>
                <ul className={list}>
                    <li>Het voorspellen van een podium per wedstrijd</li>
                </ul>
                <h2 className={h2}>2.2 SPEELPERIODE</h2>
                <p>De periode van een voorspelling kan variëren van één of meerdere wedstrijden van het klassieke voorjaar tot een volledige grote ronde. De Deelnemer die op het einde van de speelperiode de meeste punten verzamelt, wint. Een voorspelling vermeldt duidelijk over welke periode deze loopt: de begin- en einddatum worden telkens vermeld.</p>

                <h2 className={h2} >2.3 SPELFORMULES EN SCOREBEREKENING</h2>
                <p className='mt-4 font-medium mb-2'>De wieler-voorspellingen op Tifogame worden aangeboden in 3 spelformules:</p>
                <ul className={list}>
                    <li>voorspellingen die lopen over een volledige grote ronde (alle ritten)</li>
                    <li>voorspellingen die lopen over het volledige klassieke voorjaar (mannen en/of vrouwen)</li>
                    <li>voorspellingen die betrekking hebben op één of meerdere specifieke wedstrijden (mannen en/of vrouwen)</li>
                </ul>
                <p>Elke spelformule maakt gebruik van een van het type voorspelling vermeld onder 2.3. De scoreberekening verschilt volgens de spelformule.</p>
                <p className='mt-4 font-medium mb-2'>Hieronder vind je een visual met de puntenverdeling:</p>
                <img className='w-full h-auto mx-auto' src={width >= 768 ? "./assets/rules/puntentelling.png" : "./assets/rules/mobile-puntentelling.png"} alt="Puntenverdeling Tifogame"/>

                <h2 className={h2}>2.4 KLASSEMENTEN</h2>
                <p className='my-4'>Aan iedere voorspelling is een klassement verbonden. Na elke wedstrijd wordt het klassement bijgewerkt. De Deelnemer die na de laatste wedstrijd van een voorspelling op de eerste plaats staat, is de winnaar van deze voorspelling. Deze Deelnemer wint de prijs zoals vermeld onder de rubriek 'Te winnen prijzen'. Dezelfde redenering gaat op voor plaats 2, plaats 3, etc.</p>

                <h2 className={h2}>2.5 PRIJZEN</h2>
                <p className='my-4'>De organisator van een voorspelling voorziet een prijs voor de winnaar(s) van de voorspelling. In iedere voorspelling zijn de prijzen vermeld onder de rubriek 'Te winnen prijzen'. Tifogroup BV draagt geen verantwoordelijkheid in het voorzien of het toekennen van de prijs, tenzij de voorspelling georganiseerd wordt door Tifogroup BV zelf. De organisator van de voorspelling wordt aangeduid via de naam van de voorspelling en het bijhorende logo.</p>
                <p className='my-4'>Indien de winnaar minderjarig is, kan alleen zijn of haar ouders (of de persoon die het ouderlijk gezag uitoefent) de prijs in ontvangst nemen.</p>
                <p className='my-4'>Wanneer een Deelnemer een prijs gewonnen heeft op Tifogame ontvangt hij/zij een automatisch bericht met een bevestiging van de prijs. Binnen de week na deze e-mail ontvangt de Deelnemer een tweede mail met meer informatie over de prijsafhandeling.</p>
                <p className='my-4'>Reageert de Deelnemer niet op deze tweede mail, dan ontvangt hij of zij een herinneringsmail met een deadline. Reageert de Deelnemer niet voor de vermelde deadline, dan staat het Tifogroup BV vrij om de prijs niet meer toe te kennen aan de Deelnemer.</p>
                <p className='my-4'>Het staat Tifogroup BV steeds vrij om een voorspelling te sluiten op elk moment, zonder toekenning van prijs aan de winnaar of winnaars. </p>
                <p className='my-4'>Het staat de organisator van een pronostiek steeds vrij om prijzen niet te overhandigen aan onrechtmatige winnaars.</p>

                <h2 className={h2}>2.6 KENNISVRAAG + SCHIFTINGSVRAGEN</h2>
                <p className='my-4'>Iedere voorspelling aangeboden op Tifogame.be is een wedstrijd en bevat een schiftingsvraag.</p>

                <h2 className={h2}>2.7 EX AEQUO</h2>
                <p className='my-4'>Bij een ex aequo wordt rekening gehouden met het antwoord op de schiftingsvraag.</p>
                <p className='my-4'>Voorbeeld:<br></br>Deelnemer A en Deelnemer B eindigen elk met 75 punten in de voorspelling van handelaar X. Er is 1 schiftingsvraag: De gemiddelde snelheid van de winnaar. Deze bedroeg 45 km/u Deelnemer A koos voor 43 km/u corners. Deelnemer B koos voor 48 km/u. Deelnemer A heeft dus een verschil van 2 (45 – 43 =2). Deelnemer B heeft een verschil van 3 (45 – 48 = -3). Deelnemer A wordt dus het hoogst geklasseerd.</p>
                <p className='my-4'>Indien er na de schifting nog een ex aequo zou bestaan dan beslist het tijdstip waarop de voorspelling werd ingevuld: de Deelnemer met de vroegst ingevulde voorspelling wint.</p>

                <h1 className={h1}>3. SPELERSPROFIEL</h1>
                <p className='my-4'>Op het dashboard vindt de Deelnemer zijn profiel terug. In het profiel kan de Deelnemer zijn of haar gegevens aanpassen.</p>

                <p className='my-4'>Heb je vragen of is er iets niet duidelijk? Stuur een mailtje naar <Link to="mailto:info@tifogame.be" className='underline text-tifo-light'>info@tifogame.be</Link> of stel je vraag via onze Facebookpagina. Wij antwoorden jou zo snel mogelijk.</p>
            </div>
        </div>
        }

        {/* Engels */}
        {i18n.language === 'en' && 
        <div className='flex justify-center'>
            <div className='max-w-5xl w-full mx-4 pb-16'>
                <h1 className={h1}>1. GENERAL</h1>
                <h2 className={h2}>1.1 CONDITIONS OF PARTICIPATION</h2>
                <ul className={list}>
                    <li>Any adult natural person can participate in the predictions offered on <Link to="/" className='underline text-tifo-light'>tifogame.be</Link>.</li>
                    <li>Minors may participate in the predictions provided they have obtained prior permission from their parents (or the person exercising parental authority). If a minor participates, it means that he/she has obtained such permission. Tifogroup BV reserves the right to request written confirmation of this permission at any time.</li>
                    <li>A Participant may only have one account. Tifogroup BV may delete a Participant's accounts if there are reasonable suspicions that he/she is using multiple accounts. Tifogroup BV reserves the right not to award prizes if there are reasonable suspicions that a Participant is cheating.</li>
                    <li>A Participant may only participate once in the same prediction. </li>
                    <li>Predictions organized in collaboration with local authorities aim to support local merchants. Participation is only possible if the Participant has received the code physically at a relevant merchant AND resides in the municipality/city or neighboring municipality. Tifogroup BV reserves the right to remove Participants if there are reasonable suspicions of cheating.</li>
                    <li>For internal competitions organized by a company, every participant on the platform who is employed by the company in question is entitled to the prize package offered.</li>
                </ul>
                <h2 className={h2}>1.2 TIFOGAME CYCLING</h2>
                <p>Tifogame is a product of Tifogroup BV. Cycling.tifogame.be is an online platform where Participants can place free predictions for various cycling races.</p>
                <p className={bold}>There is never a bet in cash by a participant </p>

                <h1 className={h1}>2. PRONOSTICS</h1>
                <h2 className={h2}>2.1 TYPES OF PREDICTIONS</h2>
                <p className='mt-4 font-medium mb-2'>Tifogame uses one type of predictions:</p>
                <ul className={list}>
                    <li>Predicting a podium per race</li>
                </ul>
                <h2 className={h2}>2.2 GAME PERIOD</h2>
                <p>The prediction period can vary from one or more races of the classic spring season to an entire grand tour. The Participant who has accumulated the most points at the end of the game period wins. A prediction clearly states the period it covers: the start and end dates are always mentioned.</p>

                <h2 className={h2}>2.3 GAME FORMULAS AND SCORE CALCULATION</h2>
                <p className='mt-4 font-medium mb-2'>Cycling predictions on Tifogame are offered in 3 game formulas:</p>
                <ul className={list}>
                    <li>Predictions covering an entire grand tour (all stages) </li>
                    <li>Predictions covering the entire classic spring season (men and/or women)</li>
                    <li>Predictions concerning one or more specific races (men and/or women)</li>
                </ul>
                <p>Each game formula uses one of the types of prediction mentioned under 2.3. The score calculation varies according to the game formula.</p>
                <p className='mt-4 font-medium mb-2'>Below is a visual with the points distribution:</p>
                <img className='w-full h-auto mx-auto' src={width >= 768 ? "./assets/rules/puntentelling.png" : "./assets/rules/mobile-puntentelling.png"} alt="Puntenverdeling Tifogame"/>

                <h2 className={h2}>2.4 RANKINGS</h2>
                <p className='my-4'>Each prediction is associated with a ranking. After each race, the ranking is updated. The Participant who is in first place after the last race of a prediction is the winner of that prediction. This Participant wins the prize as mentioned under the 'Prizes to be won' section. The same logic applies to the second place, third place, etc.</p>

                <h2 className={h2}>2.5 PRIZES</h2>
                <p className='my-4'>The organizer of a prediction provides a prize for the winner(s) of the prediction. In each prediction, the prizes are listed under the 'Prizes to be won' section.</p>
                <p className='my-4'>Tifogroup BV is not responsible for providing or awarding the prize unless the prediction is organized by Tifogroup BV itself.</p>
                <p className='my-4'>The organizer of the prediction is indicated by the name of the prediction and the corresponding logo. If the winner is a minor, only his or her parents (or the person exercising parental authority) can receive the prize.</p>
                <p className='my-4'>When a Participant has won a prize on Tifogame, he/she receives an automatic message confirming the prize. Within a week after this email, the Participant receives a second email with more information about the prize handover. If the Participant does not respond to this second email, he/she receives a reminder email with a deadline. If the Participant does not respond before the mentioned deadline, Tifogroup BV is free not to award the prize to the Participant. Tifogroup BV is free not to award the prize to the Participant.</p>
                <p className='my-4'>Tifogroup BV is always free to close a prediction at any time without awarding a prize to the winner or winners. </p>
                <p className='my-4'>The organizer of a prediction is always free not to hand over prizes to illegitimate winners.</p>

                <h2 className={h2}>2.6 KNOWLEDGE QUESTION + TIEBREAKER QUESTIONS</h2>
                <p className='my-4'>Every prediction offered on Tifogame.be is a contest and contains a tiebreaker question.</p>

                <h2 className={h2}>2.7 TIE</h2>
                <p className='my-4'>In the event of a tie, the answer to the tiebreaker question is considered. </p>
                <p className='my-4'>Example:<br></br>Participant A and Participant B each end with 75 points in the prediction of merchant X. There is 1 tiebreaker question: The average speed of the winner. It was 45 km/h. Participant A chose 43 km/h. Participant B chose 48 km/h. Participant A has a difference of 2 (45 – 43 = 2). Participant B has a difference of 3 (45 – 48 = -3). Participant A is therefore ranked the highest.</p>
                <p className='my-4'>If there is still a tie after the tiebreaker, the time at which the prediction was filled in decides: the Participant who filled in the prediction the earliest wins.</p>

                <h1 className={h1}>3. PLAYER PROFILE</h1>
                <p className='my-4'>On the dashboard, the Participant finds his profile. In the profile, the Participant can adjust his or her details. </p>

                <p className='my-4'>Do you have questions or is something not clear? Send an e-mail to <Link to="mailto:info@tifogame.be" className='underline text-tifo-light'>info@tifogame.be</Link> or ask your question via our Facebook page. We will answer you as soon as possible.</p>
            </div>
        </div>
        }

        {/* Frans */}
        {i18n.language === 'fr' && 
        <div className='flex justify-center'>
            <div className='max-w-5xl w-full mx-4 pb-16'>
                <h1 className={h1}>1. GÉNÉRAL</h1>
                <h2 className={h2}>1.1 CONDITIONS DE PARTICIPATION</h2>
                <ul className={list}>
                    <li>Toute personne physique majeure peut participer aux prédictions offertes sur <Link to="/" className='underline text-tifo-light'>tifogame.be</Link>.</li>
                    <li>Les mineurs peuvent participer aux prédictions à condition d'avoir obtenu au préalable l'autorisation de leurs parents (ou de la personne exerçant l'autorité parentale). Si un mineur participe, cela signifie qu'il/elle a obtenu cette autorisation. Tifogroup BV se réserve le droit de demander à tout moment la confirmation écrite de cette autorisation. </li>
                    <li>Un Participant ne peut avoir qu'un seul compte. Tifogroup BV peut supprimer les comptes d'un Participant s'il existe des soupçons raisonnables qu'il/elle utilise plusieurs comptes. Tifogroup BV se réserve le droit de ne pas attribuer de prix s'il existe des soupçons raisonnables que le Participant triche.</li>
                    <li>Un Participant ne peut participer qu'une seule fois à une même prédiction. </li>
                    <li>Les prédictions organisées en collaboration avec les administrations locales ont pour but de soutenir les commerçants locaux. La participation n'est possible que si le Participant a reçu le code physiquement chez un commerçant concerné ET réside dans la commune/ville ou dans une commune voisine. Tifogroup BV se réserve le droit de supprimer des Participants s'il existe des soupçons raisonnables de tricherie.</li>
                    <li>Pour les concours internes organisés par une entreprise, chaque participant sur la plateforme, employé de l'entreprise en question, a droit au prix offert.</li>
                </ul>
                <h2 className={h2}>1.2 TIFOGAME CYCLING</h2>
                <p>Tifogame est un produit de Tifogroup BV. Cycling.tifogame.be est une plateforme en ligne où les Participants peuvent faire gratuitement des prédictions pour différentes courses cyclistes. Il n'y a jamais de mise en argent par un participant.</p>
                <p className={bold}>Les participants ne parient jamais en espèces. </p>

                <h1 className={h1}>2. PRÉDICTIONS</h1>
                <h2 className={h2}>2.1 TYPES DE PRÉDICTIONS</h2>
                <p className='mt-4 font-medium mb-2'>Tifogame utilise un type de prédiction:</p>
                <ul className={list}>
                    <li>Prédiction d'un podium par course.</li>
                </ul>
                <h2 className={h2}>2.2 PÉRIODE DE JEU</h2>
                <p>La période de prédiction peut varier d'une ou plusieurs courses du printemps classique à un grand tour complet. Le Participant qui a accumulé le plus de points à la fin de la période de jeu gagne. Une prédiction indique clairement sur quelle période elle porte : les dates de début et de fin sont toujours mentionnées.</p>

                <h2 className={h2}>2.3 FORMULES DE JEU ET CALCUL DES POINTS</h2>
                <p className='mt-4 font-medium mb-2'>Les prédictions cyclistes sur Tifogame sont proposées en 3 formules de jeu:</p>
                <ul className={list}>
                    <li>prédictions couvrant un grand tour complet (toutes les étapes) </li>
                    <li>prédictions couvrant tout le printemps classique (hommes et/ou femmes)</li>
                    <li>prédictions concernant une ou plusieurs courses spécifiques (hommes et/ou femmes) </li>
                </ul>
                <p>Chaque formule de jeu utilise un des types de prédiction mentionnés sous 2.3. Le calcul des points varie selon la formule de jeu. </p>
                <p className='mt-4 font-medium mb-2'>Voici une visualisation avec la répartition des points:</p>
                <img className='w-full h-auto mx-auto' src={width >= 768 ? "./assets/rules/puntentelling.png" : "./assets/rules/mobile-puntentelling.png"} alt="Puntenverdeling Tifogame"/>

                <h2 className={h2}>2.4 CLASSEMENTS</h2>
                <p className='my-4'>Chaque prédiction est liée à un classement. Après chaque course, le classement est mis à jour. Le Participant qui est en tête après la dernière course d'une prédiction est le gagnant de cette prédiction. Ce Participant gagne le prix mentionné sous la rubrique 'Prix à gagner'. La même logique s'applique pour la deuxième place, la troisième place, etc.</p>

                <h2 className={h2}>2.5 PRIX</h2>
                <p className='my-4'>L'organisateur d'une prédiction prévoit un prix pour le(s) gagnant(s) de la prédiction. Dans chaque prédiction, les prix sont mentionnés sous la rubrique 'Prix à gagner'. Tifogroup BV n'est pas responsable de la fourniture ou de l'attribution du prix, sauf si la prédiction est organisée par Tifogroup BV elle-même.</p>
                <p className='my-4'>L'organisateur de la prédiction est indiqué par le nom de la prédiction et le logo correspondant. Si le gagnant est mineur, seuls ses parents (ou la personne exerçant l'autorité parentale) peuvent recevoir le prix. Lorsqu'un Participant gagne un prix sur Tifogame, il/elle reçoit un message automatique confirmant le prix. </p>
                <p className='my-4'>Dans la semaine suivant cet e-mail, le Participant reçoit un second mail avec plus d'informations sur la remise du prix. Si le Participant ne répond pas à ce second mail, il/elle reçoit un mail de rappel avec une date limite.</p>
                <p className='my-4'>Si le Participant ne répond pas avant la date limite mentionnée, Tifogroup BV est libre de ne plus attribuer le prix au Participant.</p>
                <p className='my-4'>Tifogroup BV est toujours libre de fermer une prédiction à tout moment, sans attribution de prix au gagnant ou aux gagnants.</p>
                <p className='my-4'>L'organisateur d'une prédiction est toujours libre de ne pas remettre de prix aux gagnants illégitimes.</p>

                <h2 className={h2}>2.6 QUESTION DE CONNAISSANCE + QUESTIONS DE DÉPARTAGE</h2>
                <p className='my-4'>Chaque prédiction proposée sur Tifogame.be est un concours et contient une question de départage.</p>

                <h2 className={h2}>2.7 EX AEQUO</h2>
                <p className='my-4'>En cas d'égalité, la réponse à la question de départage est prise en compte.</p>
                <p className='my-4'>Exemple:<br></br>Le Participant A et le Participant B terminent chacun avec 75 points dans la prédiction du commerçant X. Il y a 1 question de départage : la vitesse moyenne du vainqueur. Elle était de 45 km/h. Le Participant A a choisi 43 km/h. Le Participant B a choisi 48 km/h. Le Participant A a donc une différence de 2 (45 – 43 =2). Le Participant B a une différence de 3 (45 – 48 = -3). Le Participant A est donc classé le plus haut. En cas de nouvelle égalité après le départage, le moment de la prédiction est décisif : le Participant ayant fait sa prédiction le plus tôt l'emporte.</p>

                <h1 className={h1}>3. PROFIL DU JOUEUR</h1>
                <p className='my-4'>Sur le tableau de bord, le Participant retrouve son profil. Dans le profil, le Participant peut modifier ses informations.</p>

                <p className='my-4'>Vous avez des questions ou quelque chose n'est pas clair? Envoyez un e-mail à <Link to="mailto:info@tifogame.be" className='underline text-tifo-light'>info@tifogame.be</Link> ou posez votre question via notre page Facebook. Nous vous répondrons dans les plus brefs délais.</p>
            </div>
        </div>
        }

        {/* Duits */}
        {i18n.language === 'de' && 
        <div className='flex justify-center'>
            <div className='max-w-5xl w-full mx-4 pb-16'>
                <h1 className={h1}>1. ALLGEMEIN</h1>
                <h2 className={h2}>1.1 TEILNAHMEBEDINGUNGEN</h2>
                <ul className={list}>
                    <li>Jede volljährige natürliche Person kann am Tippspiel auf <Link to="/" className='underline text-tifo-light'>tifogame.be</Link> teilnehmen.</li>
                    <li>Minderjährige dürfen am Tippspiel teilnehmen, wenn sie zuvor die Zustimmung ihrer Eltern (oder der Person, die das elterliche Sorgerecht ausübt) eingeholt haben. Die Teilnahme eines Minderjährigen setzt voraus, dass er/sie eine solche Zustimmung erhalten hat. Tifogroup BV behält sich das Recht vor, jederzeit die schriftliche Bestätigung dieser Zustimmung anzufordern.</li>
                    <li>Ein Teilnehmer darf nur über ein Konto verfügen. Tifogroup BV kann die Konten eines Teilnehmers löschen, wenn es berechtigte Vermutungen gibt, dass er/sie mehrere Konten verwendet. Tifogroup BV behält sich das Recht vor, Preise nicht zu vergeben, wenn berechtigte Vermutungen bestehen, dass ein Teilnehmer betrügt. </li>
                    <li>Ein Teilnehmer darf nur einmal an derselben Vorhersage teilnehmen.</li>
                    <li>Die Tippspiele, die in Zusammenarbeit mit den lokalen Behörden organisiert werden, haben zum Ziel, lokale Händler zu unterstützen. Eine Teilnahme ist nur möglich, wenn der Teilnehmer den Code physisch bei einem entsprechenden Händler erhalten hat UND in der Gemeinde/Stadt oder der benachbarten Gemeinde wohnhaft ist. Tifogroup BV behält sich das Recht vor, Teilnehmer zu entfernen, wenn es berechtigte Vermutungen gibt, dass sie betrügen..</li>
                    <li>Für interne Wettbewerbe, die von einem Unternehmen organisiert werden, gilt, dass jeder Teilnehmer auf der Plattform, der bei dem betreffenden Unternehmen beschäftigt ist, Anspruch auf das angebotene Preisangebot hat.</li>
                </ul>
                <h2 className={h2}>1.2 TIFOGAME CYCLING</h2>
                <p>Tifogame ist ein Produkt der Tifogroup BV. Cycling.tifogame.be ist eine Online-Plattform, auf der Teilnehmer kostenlose Tipps abgeben können für verschiedene Radrennen. </p>
                <p className={bold}>Es wird von keinem Teilnehmer ein Geldeinsatz verlangt.</p>

                <h1 className={h1}>2. VORHERSAGEN</h1>
                <h2 className={h2}>2.1 ARTEN VON VORHERSAGEN</h2>
                <p className='mt-4 font-medium mb-2'>Tifogame bietet eine Art von Tippspiel an:</p>
                <ul className={list}>
                    <li>Vorhersage eines Podiums pro Rennen</li>
                </ul>
                <h2 className={h2}>2.2 SPIELDAUER</h2>
                <p>Die Dauer eines Tippspiels kann von einem oder mehreren Rennen des klassischen Frühjahrs bis hin zu einer vollständigen großen Rundfahrt variieren. Der Teilnehmer, der am Ende der Spielzeit die meisten Punkte gesammelt hat, gewinnt. Ein Tippspiel gibt deutlich an, über welchen Zeitraum es läuft: Start- und Enddatum werden immer genannt.</p>

                <h2 className={h2}>2.3 SPIELFORMELN UND PUNKTEBERECHNUNG</h2>
                <p className='mt-4 font-medium mb-2'>Die Rad-Tippspiele auf Tifogame werden in 3 Spielformeln angeboten:</p>
                <ul className={list}>
                    <li>Tippspiele, die eine vollständige große Rundfahrt abdecken (alle Etappen) </li>
                    <li>Tippspiele, die die gesamte klassische Frühjahrssaison abdecken (Männer und/oder Frauen)</li>
                    <li>Tippspiele, die sich auf ein oder mehrere spezifische Rennen beziehen (Männer und/oder Frauen)</li>
                </ul>
                <p>Jede Spielformel verwendet eine der unter 2.3 genannten Tippspielarten. Die Punkteberechnung variiert je nach Spielformel.</p>
                <p className='mt-4 font-medium mb-2'>Unten finden Sie eine visuelle Darstellung der Punkteverteilung:</p>
                <img className='w-full h-auto mx-auto' src={width >= 768 ? "./assets/rules/puntentelling.png" : "./assets/rules/mobile-puntentelling.png"} alt="Puntenverdeling Tifogame"/>

                <h2 className={h2}>2.4 RANGLISTEN</h2>
                <p className='my-4'>Jedem Tippspiel ist eine Rangliste zugeordnet. Nach jedem Rennen wird die Rangliste aktualisiert. Der Teilnehmer, der nach dem letzten Rennen eines Tippspiels an erster Stelle steht, ist der Gewinner dieses Tippspiels. Dieser Teilnehmer gewinnt den Preis, wie unter dem Abschnitt 'Zu gewinnende Preise' angegeben. Dasselbe gilt für den zweiten Platz, den dritten Platz usw.</p>

                <h2 className={h2}>2.5 PREISE</h2>
                <p className='my-4'>Der Veranstalter eines Tippspiels stellt einen Preis für die Gewinner des Tippspiels zur Verfügung. In jedem Tippspiel sind die Preise unter dem Abschnitt 'Zu gewinnende Preise' aufgeführt.</p>
                <p className='my-4'>Tifogroup BV ist nicht dafür verantwortlich, den Preis zu liefern oder zu vergeben, es sei denn, das Tippspiel wird von Tifogroup BV selbst organisiert. Der Veranstalter des Tippspiels wird durch den Namen des Tippspiels und das entsprechende Logo angezeigt.</p>
                <p className='my-4'>Wenn der Gewinner minderjährig ist, können nur seine Eltern (oder die Person, die das elterliche Sorgerecht ausübt), den Preis entgegennehmen. </p>
                <p className='my-4'>Wenn ein Teilnehmer auf Tifogame einen Preis gewonnen hat, erhält er/sie eine automatische Nachricht zur Bestätigung des Preises. Innerhalb einer Woche nach dieser E-Mail erhält der Teilnehmer eine zweite E-Mail mit weiteren Informationen zur Abwicklung des Preises. Wenn der Teilnehmer nicht auf diese zweite E-Mail antwortet, erhält er/sie eine Erinnerungs-E-Mail mit einer Frist. Wenn der Teilnehmer nicht vor der angegebenen Frist reagiert, ist Tifogroup BV frei, den Preis nicht mehr an den Teilnehmer zu vergeben.</p>
                <p className='my-4'>Tifogroup BV steht es jederzeit frei, eine Vorhersage zu einem beliebigen Zeitpunkt zu schließen, ohne den Gewinner oder die Gewinner zu belohnen.</p>
                <p className='my-4'>Der Veranstalter eines Tippspiels ist jederzeit frei, Preise nicht an illegitime Gewinner zu übergeben.</p>

                <h2 className={h2}>2.6 WISSENSFRAGE + TIEBREAKER-FRAGEN</h2>
                <p className='my-4'>Jedes auf Tifogame.be angebotene Tippspiel ist ein Wettbewerb und enthält eine Tiebreaker-Frage.</p>

                <h2 className={h2}>2.7 UNENTSCHIEDEN</h2>
                <p className='my-4'>Im Falle eines Unentschiedens wird die Antwort auf die Tiebreaker-Frage berücksichtigt.</p>
                <p className='my-4'>Beispiel:<br></br>Teilnehmer A und Teilnehmer B enden jeweils mit 75 Punkten im Tippspiel des Händlers X. Es gibt 1 Tiebreaker-Frage: Die Durchschnittsgeschwindigkeit des Gewinners. Sie betrug 45 km/h. Teilnehmer A wählte 43 km/h. Teilnehmer B wählte 48 km/h. Teilnehmer A hat einen Unterschied von 2 (45 – 43 = 2). Teilnehmer B hat einen Unterschied von 3 (45 – 48 = -3). Teilnehmer A wird daher höher eingestuft.</p>
                <p className='my-4'>Wenn nach dem Tiebreaker noch ein Unentschieden besteht, entscheidet die Zeit, zu der das Tippspiel ausgefüllt wurde: Der Teilnehmer, der das Tippspiel am frühesten ausgefüllt hat, gewinnt.</p>

                <h1 className={h1}>3. SPIELERPROFIL</h1>
                <p className='my-4'>Auf dem Dashboard findet der Teilnehmer sein Profil. Im Profil kann der Teilnehmer seine Details anpassen. </p>

                <p className='my-4'>Haben Sie Fragen oder ist etwas unklar? Senden Sie eine E-Mail an <Link to="mailto:info@tifogame.be" className='underline text-tifo-light'>info@tifogame.be</Link> oder stellen Sie Ihre Frage über unsere Facebook-Seite. Wir antworten Ihnen so schnell wie möglich.</p>
            </div>
        </div>
        }

        <Footer />
    </>
    )
}

export default GameRules
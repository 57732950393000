import React, { useState } from 'react'
import balloon1 from '../../../assets/images/bg/balloon-1.png'
import balloon2 from '../../../assets/images/bg/balloon-2.png'
import balloon3 from '../../../assets/images/bg/balloon-3.png'
import cloud from '../../../assets/images/bg/cloud.png'
import logo from '../../../assets/logo/boxlogo.svg'
import { motion as m } from 'framer-motion'
import "./animation.scss"
import { Button } from '../../../components/ui/button'
import { Label } from '../../../components/ui/label'
import { Input } from '../../../components/ui/input'
import { Dot, Loader2, MoveRight } from 'lucide-react'
import {Link} from 'react-router-dom'

const Login = () => {
    const [isFocussed, setisFocussed] = useState(false);
    const [isPassFocussed, setisPassFocussed] = useState(false);
    const [isHovered, setisHovered] = useState(false);

    const [is_loading, setis_loading] = useState(false);
    const [start_animation, setstart_animation] = useState(false);
    
    const handleLogin = async () => {
        const delay = ms => new Promise(res => setTimeout(res, ms));

        setis_loading(true)
        await delay(2000)
        setstart_animation(true)
        setis_loading(false)
    }

  return (
    <>
    {start_animation && <div className='fixed overflow-hidden w-screen h-screen top-0 left-0 z-10'><m.div initial={{width:'200px', height:'200px', borderRadius:'100%', opacity:.6}} animate={{width:'200%', height:'200%', opacity:1}} transition={{ease:'easeOut', duration:1}} className='absolute w-10 h-10 bg-white z-10 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'></m.div></div>}
    <div className='grid grid-cols-2 h-full'>
        <div className='grid grid-rows-3 p-16'>
            <div className='flex items-end'><h1 className='text-3xl font-bold select-none flex gap-2 items-center text-tifo'><span><img src={logo}/></span>Tifogame</h1></div>
            
            <div className='flex flex-col gap-4 justify-center w-full'>
                <div>
                    <Label>E-mailadres</Label>
                    <Input onFocus={()=>{setisFocussed(true)}} onBlur={()=>{setisFocussed(false)}} type='email' placeholder='john@email.com'/>
                </div>
                <div>
                    <Label>Wachtwoord</Label>
                    <Input onFocus={()=>{setisPassFocussed(true)}} onBlur={()=>{setisPassFocussed(false)}} type='password' placeholder='*********'/>
                </div>
                <Button onClick={handleLogin} disabled={is_loading} onMouseOver={()=>{setisHovered(true)}} onMouseLeave={()=>{setisHovered(false)}} className='gap-2'>{is_loading ? <Loader2 className='w-4 animate-spin'/> : <>Inloggen <MoveRight className='w-4'/></>}</Button>
            </div>

            <div className='flex items-end'>
                <div className='flex gap-4 items-center'>
                <Link to="/" className='opacity-50 text-sm border-b hover:opacity-75'>Ga naar onze website</Link>
                <Dot className='opacity-25'/>
                <Link to="/" className='opacity-50 text-sm border-b hover:opacity-75'>Terms and Conditions</Link>
                <Dot className='opacity-25'/>
                <Link to="/" className='opacity-50 text-sm border-b hover:opacity-75'>Support</Link>
                </div>
            </div>
        </div>
        <div className='relative bg-gradient-to-bl from-pink-50 to-blue-200 overflow-hidden p-10'>
            <p className='max-w-[300px] text-slate-600 opacity-75 select-none'>Deze inlogpagina is bedoeld voor beheer doeleinden.</p>
            <img className='absolute opacity-75 z-[2] right-0 translate-y-1/2 bottom-0 scale-[2]' src={cloud} />
            <img className='absolute opacity-75 z-[2] top-1/4' src={cloud} />
            <img className='absolute top-1/2 left-1/2 -translate-y-full opacity-75 z-[2] scale-150' src={cloud} />
            <img className='absolute bottom-10 opacity-50 z-[2] scale-125' src={cloud} />
            <img className='absolute bottom-10 -translate-y-full right-0 opacity-75 z-[2]' src={cloud} />
            <m.img className={`animationFloatDown ${isPassFocussed && 'animationFloatDownScale'} duration-1000 absolute left-0 top-1/3 w-[25vw]`} src={balloon1} />
            <m.img className={`animationFloatUp ${isFocussed && 'animationFloatUpScale'} duration-1000 absolute left-1/4 top-1/3 w-[30vw] z-[1]`} src={balloon3} />
            <m.img className={`animationFloatDown ${isHovered && 'animationFloatDownScale'} duration-1000 delay-200 absolute right-0 bottom-4 w-[25vw]`} src={balloon2} />
        </div>
    </div>
    </>
  )
}

export default Login
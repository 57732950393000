import { useEffect, useState } from "react";
import { filterArrayByValue } from "../lib/filter";
import lodash from 'lodash'

type Props<T, K extends keyof T = keyof T> = {
    data: T[];
    sorting?: ColumnSort;
    filter?: string;
    keysToFilterOn?: K[];
};

export interface ColumnSort {
    desc: boolean
    id: string
}

function getValue(o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

function useTable<T> (options: Props<T>) {
    const [data, setData] = useState(options.data);
    const [sorting, setSorting] = useState(options.sorting);
    const [filter, setFilter] = useState(options.filter);

    useEffect(() => {
        // Create a copy of the initial data to avoid modifying the original array
        let newData = [...options.data];

        // Sort the data based on the sorting state
        if (sorting) {
            // Sort the data based on the sorting state
            newData.sort((a, b) => {
                const { id, desc } = sorting;
                const valueA = getValue(a, id);
                const valueB = getValue(b, id);

                if (typeof valueA === 'string' && typeof valueB === 'string') {
                    return desc ? valueB.localeCompare(valueA) : valueA.localeCompare(valueB);
                } else {
                    return desc ? (valueB as number) - (valueA as number) : (valueA as number) - (valueB as number);
                }
                // if (sorting) {
                //     const { id, desc } = sorting;
                //     if (typeof a[id as keyof T] === 'string' && typeof b[id as keyof T] === 'string') {
                //         // For string columns, sort alphabetically
                //         return desc ? (a[id as keyof T] as string).localeCompare(b[id as keyof T] as string) : (b[id as keyof T] as string).localeCompare(a[id as keyof T] as string);
                //     } else {
                //         // For numeric or other types, use regular comparison
                //         return desc ? (b[id as keyof T] as number) - (a[id as keyof T] as number) : (a[id as keyof T] as number) - (b[id as keyof T] as number);
                //     }
                // } else {
                //     return 0; // Sorting not provided, no change in order
                // }
            });


            newData = filterArrayByValue(newData as object[], filter || '', (options.keysToFilterOn ? options.keysToFilterOn as string[] : undefined)) as T[]

            let combineAndRemoveDups = lodash.union(newData)

            setData(combineAndRemoveDups);
        }

    }, [options.data, sorting, filter])

    // Function to update the sorting state
    const updateSorting = (newSorting: ColumnSort) => {
        setSorting(newSorting);
    };

    // Function to update the filter state
    const updateFilter = (newFilter:string) => {
        setFilter(newFilter);
    };


    return { data, sorting, filter, updateSorting, updateFilter };
}


export default useTable;
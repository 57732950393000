import { useLocation, Navigate, Outlet, useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { CYCLO_PARTNER_CONFIG, getCycloPartnerConfig } from "../config/cyclogame-partner-config";

const RequireAuth = ({ } : { }) => {
    const { auth } = useAuth();
    const location = useLocation();
    const { partnername } = useParams()

    let loginURL = "/"
    if(partnername){
        const partnerId = Object.keys(CYCLO_PARTNER_CONFIG).find(id => CYCLO_PARTNER_CONFIG[id].info?.name.toLowerCase() === partnername);
        if(partnerId){
          const inviteURL = getCycloPartnerConfig(partnerId)?.invite?.url
          if(inviteURL) { loginURL = inviteURL }
        }
    }

    return (
        (auth.data)
            ? <Outlet />
            : auth?.data
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <>{auth.status !== 'loading' 
                    ? <Navigate to={loginURL} state={{ from: location }} replace /> 
                    : <>Loading...</>}
                   </>
    );
}

export default RequireAuth;
export const CYCLO_PARTNER_CONFIG:CYCLO_PARTNER_CONFIG_PROPS = {
    default: {
        prono: {
            view_ride_profile: false
        },
        event: {
            podium_prizes: false
        },
        partner: {
            view_departments: true
        }
    },
    '13': {
        info: {
            name: 'tomabel'
        },
        prono: {
            view_ride_profile: true
        },
        event: {
            podium_prizes: true
        },
        partner: {
            view_departments: false
        },
        invite: {
            url: '/uitnodiging/tomabel/160/56b835c99557428aa18981b9b6388f13'
        }
    }
}

export const getCycloPartnerConfig = (partnerId:string | number) => {
    const config = CYCLO_PARTNER_CONFIG[partnerId.toString()]
    if(config) { return config }
    return CYCLO_PARTNER_CONFIG.default
}

interface CYCLO_PARTNER_CONFIG_PROPS {
    [partnerId:string]: {
        info?: {
            name?: string
        },
        prono: {
            view_ride_profile: boolean
        },
        event: {
            podium_prizes: boolean
        },
        partner: {
            view_departments: boolean
        },
        invite?: {
            url: string
        }
    }
}
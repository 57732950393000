import { ActionType } from "../action-types"

export const setLoginPopup = (active:boolean) => {
    return ({
        type: ActionType.SET_LOGIN_POPUP,
        payload: active 
    })
}

export const setUserdata = (userdata:User) => {
    return ({
        type: ActionType.SET_USERDATA,
        payload: userdata
    })
}

import { config } from "../config/config"

export const eventsURL = () => {
    let url = 'events?'

    // if(localStorage.getItem('authToken')){
    //     // Probleem homepage alle events ophalen -> enkel partner events ophalen
    //     url = 'partner/events?'
    // }

    const sportIds = config.global.sportid.join(';')
    if(sportIds){ url+=`&sportid=${sportIds}` }

    return url
}
import { ActionType, Action } from "../action-types";

const initialState:boolean = false;

const loginPopupReducer = (state:boolean = initialState, action:Action) => {
    switch (action.type) {
        case ActionType.SET_LOGIN_POPUP:
            state = action.payload
            return state;
        default:
            return state;
    }
}

const initialUserdata = null

export const userdataReducer = (state:User | null = initialUserdata, action:Action) => {
    switch (action.type) {
        case ActionType.SET_USERDATA:
            state = action.payload
            return state;
        default:
            return state;
    }
}

export default loginPopupReducer;
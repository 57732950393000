import React, { HTMLAttributes } from 'react'
import styles from '../../styles/components/general/Flags.module.scss'
import anonymousRider from '../../assets/images/riders/anonymous_rider.png'
import { Trash } from 'lucide-react';
import { cn } from '../../lib/utils';

interface Props {
  image?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  nationality?: Nationality | null;
  
  showDelete?: boolean;
  onDelete?: () => void;
  onClick?: () => void;

  glow?: boolean;
}

const ProfilePicture : React.FC<Props & HTMLAttributes<HTMLDivElement>> = ({image, size='md', nationality, showDelete=false, glow=false, onDelete, onClick, className}) => {
    let sizeClass = size === 'sm' ? 'w-14 h-14' : 
                    size === 'md' ? 'w-20 h-20' : 
                    size === 'lg' ? 'w-24 h-24' :
                    size === 'xl' ? 'w-32 h-32' : 'w-20 h-20'

    let flagSizeClass = size === 'sm' ? 'top-[calc(56px-14px)]' :
                        size === 'md' ? 'top-[calc(80px-14px)]' : 
                        size === 'lg' ? 'top-[calc(96px-14px)]' :
                        size === 'xl' ? 'top-[calc(128px-14px)]' : 'top-[calc(80px-14px)]'

                    

  return (
    <div className='relative group'>
      <div onClick={()=>{ if(!showDelete){ onClick && onClick() } }} className={cn(`${sizeClass} ${glow && 'shadow-[0_0_20px_rgba(70,246,166,1)]'} ${image && 'bg-tifo-light/30'} rounded-full overflow-hidden border-2 border-tifo-light relative cursor-pointer`, className)}>
            {(nationality||image) ? <img className='object-cover w-full h-full object-top' 
              onError={(e)=>{
                //@ts-ignore
                e.target.src=anonymousRider
              }} 
              src={image || anonymousRider} alt="Rider image"/>
                    : <div className='bg-neutral-600 w-full h-full'></div>}

            {showDelete && 
            <button onClick={onDelete} className='hidden group-hover:flex absolute top-0 left-0 z-[1] bg-black/50 w-full h-full rounded-full justify-center items-center'>
              <Trash className='w-5'/>
            </button>}
        </div>
        {nationality && <span className={`absolute ${flagSizeClass} ${styles.flag} ${styles[nationality.toLowerCase()]}`}></span>}
    </div>
  )
}

export default ProfilePicture
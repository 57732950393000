import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"
 
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const isInterface = <T>(variable: any, interfaceObject: T): variable is T => {
  if (interfaceObject === null || typeof interfaceObject !== 'object') {
    return false;
  }

  if (typeof interfaceObject === 'object') {
    if (variable === null || typeof variable !== 'object') {
      return false;
    }

    const interfaceKeys = Object.keys(interfaceObject as object) as Array<keyof T>;
    return interfaceKeys.every((key) => {
      const interfaceValue = interfaceObject[key];
      const variableValue = variable[key];
      return isInterface(variableValue, interfaceValue);
    });
  } else if (typeof interfaceObject === 'function') {
    return variable instanceof interfaceObject;
  } else {
    return typeof variable === typeof interfaceObject;
  }
};

export function convertToUrl(inputString: string): string {
  // Replace spaces with hyphens and convert to lowercase
  const formattedString = inputString.replace(/ /g, '-').toLowerCase();
  return formattedString;
}

export function convertToNormal(urlFormattedString: string): string {
  // Decode URL-encoded string
  const decodedString = decodeURIComponent(urlFormattedString);
  // Replace hyphens with spaces
  const normalFormatString = decodedString.replace(/-/g, ' ');
  return normalFormatString;
}

export function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

type CallbackRef<T> = (ref: T | null) => void;
type Ref<T> = React.MutableRefObject<T> | CallbackRef<T>;
export default function mergeRefs<T>(refA?: Ref<T | null> | null, refB?: Ref<T | null> | null): React.RefCallback<T> {
  const toFnRef = <T>(ref?: Ref<T | null> | null) => !ref || typeof ref === 'function' ? ref : (value: T | null) => { ref.current = value; };

  const a = toFnRef(refA);
  const b = toFnRef(refB);
  return (value: T | null) => {
    if (typeof a === 'function') a(value);
    if (typeof b === 'function') b(value);
  };
}
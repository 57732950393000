import React, { HTMLAttributes} from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table'
import { cn } from '../../lib/utils'
import { useQuery } from '@tanstack/react-query'
import axios from '../../lib/axios';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
    eventid?: number;
    departmentid?: number;
    partnerid?: number;
    pronoid?: number;
    view?: 'partner' | 'event' | 'department' | 'prono' | 'groups'
}

const SmallRankingsTable : React.FC<Props & HTMLAttributes<HTMLDivElement>> = ({eventid, departmentid, partnerid, pronoid, view='partner', className}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const { data:ranking, isFetching:isFetchingRanking } = useQuery<EventRanking[] | null>({
        queryKey: [`ranking_${eventid}_${pronoid}_${departmentid}_${view}`],
        queryFn: async () => {
            try {
                const partnerURL = partnerid ? `&partnerid=${partnerid}` : ''
                const departURL = departmentid ? `&departmentid=${departmentid}` : ''
                const eventURL = eventid ? `&eventid=${eventid}` : ''
                const pronoURL = pronoid ? `&pronoid=${pronoid}` : ''

                let url = partnerURL + departURL + eventURL + pronoURL

                const {data} = await axios.get(`ranking?${url}`)
                let res = data;

                if(view === 'groups'){
                    let result:any = data.reduce((acc:any, obj) => {
                        const departmentID = obj.m_iDepartmentID
                        if (!acc[departmentID]) {
                            acc[departmentID] = {
                              m_iDepartmentID: departmentID,
                              m_sDepartment: obj.m_sDepartment,
                              m_iPoints: 0
                            };
                          }
                          acc[departmentID].m_iPoints += obj.m_iPoints;
                          return acc;
                    }, {})
                    res = Object.values(result).sort((a:any, b:any) => b.m_iPoints - a.m_iPoints)
                }

                if(!Array.isArray(res)){return null}
                return res
            } catch (error) {
                return null
            }
        },
        enabled: !!eventid || !!departmentid || !!partnerid || !!pronoid
    })

  return (
    <Table className={cn('border-separate border-spacing-y-2', className)}>
        <TableHeader>
            <TableRow className='border-none hover:bg-transparent'>
                <TableHead className='text-white font-normal h-6'>{t("ranking.pos")}</TableHead>
                <TableHead className='text-white font-normal h-6'>{t("ranking.name")}</TableHead>
                <TableHead className='text-white font-normal h-6'>{t("ranking.pts")}</TableHead>
            </TableRow>
        </TableHeader>
        <TableBody>
            {ranking && ranking.slice(0,5).sort((a,b)=>b.m_iPoints-a.m_iPoints).map((rank, index)=>{
                return (
                <TableRow className={`${rank.m_iUserID === auth.data.m_iUserID ? 'text-tifo-light' : 'text-white'} bg-white/10 hover:bg-white/20`} key={index}>
                    <TableCell className='py-3 rounded-l-sm overflow-hidden'>{index+1}</TableCell>
                    <TableCell className='py-3'>
                        {view === 'groups'
                        ? <>{rank.m_sDepartment}</>
                        : <>{rank.m_sUser} {rank.m_iUserID === auth.data.m_iUserID && ` (${t("ranking.you")}) `}</>}
                    </TableCell>
                    <TableCell className='py-3 rounded-r-sm'>{rank.m_iPoints}</TableCell>
                </TableRow>
                )
            })}
            {ranking && ranking.find(i=>i.m_iUserID === auth.data.m_iUserID) && ranking.findIndex(i=>i.m_iUserID === auth.data.m_iUserID) > 4 && 
            <TableRow className='text-tifo-light bg-white/10 hover:bg-white/20'>
                <TableCell className='py-3 rounded-l-sm overflow-hidden'>{ranking.findIndex(i=>i.m_iUserID === auth.data.m_iUserID)+1}</TableCell>
                <TableCell className='py-3'>
                    {view === 'groups'
                    ? <></>
                    : <>{auth.data.m_sFirstName} ({t("ranking.you")})</>}
                </TableCell>
                <TableCell className='py-3 rounded-r-sm'>{ranking.find(i=>i.m_iUserID === auth.data.m_iUserID)?.m_iPoints}</TableCell>
            </TableRow>
            }

            {ranking && 
            <TableRow className='text-white bg-transparent hover:bg-transparent'>
                <TableCell colSpan={99}>
                    <div className='flex justify-center items-center'>
                        <Link to="/klassementen" className='uppercase underline text-tifo-light text-center'>{t("event.more")}</Link>
                    </div>
                </TableCell>
            </TableRow>
            }

            {(!ranking && !isFetchingRanking) && <TableRow className='text-white bg-white/10'><TableCell colSpan={3}>{t("ranking.no_ranking_title")}...</TableCell></TableRow>}
        </TableBody>
    </Table>
  )
}

export default SmallRankingsTable
import React, { useEffect, useRef } from 'react'
import top from '../../assets/images/bg/003.jpg'
import prize from '../../assets/images/bg/009.png'

import MoveDown from '../../assets/icons/move-down.svg'
import LogoOpacity from '../../assets/images/illustrations/logo-white-opacity.png'
import Slider001 from '../../assets/images/bg/010.jpg'
import Slider002 from '../../assets/images/bg/011.jpg'
import Slider003 from '../../assets/images/bg/006.jpg'
import Slider004 from '../../assets/images/bg/007.jpg'
import Slider005 from '../../assets/images/bg/008.jpg'
import Faq001 from '../../assets/images/bg/013.jpg'
import { ChevronRight } from 'lucide-react'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../components/ui/accordion'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../../layouts/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { setLoginPopup } from '../../redux/actions'
import useAuth from '../../hooks/useAuth'
import { RootState } from '../../redux'
import axios from '../../lib/axios'
import { useQuery } from '@tanstack/react-query'
import { eventsURL } from '../../lib/fetch'
import { ScrollArea } from '../../components/ui/scroll-area'
import { useTranslation } from 'react-i18next'
import { getTranslationString } from '../../lib/translation'
import { toEventOrPartner } from '../../lib/linking'
import { isAfter } from 'date-fns'

const Top = () => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    const { auth } = useAuth()
    const pressedLogin = useRef(false)
    const redirectUser = useRef(false)

    const dispatch = useDispatch()

    function showLogin() {
        pressedLogin.current = true
        dispatch(setLoginPopup(true))
    }

    useEffect(()=>{
        if(!!auth.data && pressedLogin.current){
            // User just logged in and pressed "Next" in login-popup
            // Redirect the user to the latest prono
            console.log("redirect")
            redirectUser.current = true
        }
    }, [auth.data])

    const { data, refetch:refetchEvents } = useQuery<EventClass[] | null>({
        queryKey: [`events`, auth?.data?.m_iUserID],
        queryFn: async () => {
          try {
            const {data} = await axios.get(`${eventsURL()}`)
            let filteredData = data.filter(i=>i.m_bActive===true)
            if(!Array.isArray(filteredData)){throw new Error('No array')}
            console.log(filteredData)
            return filteredData
          } catch (error) {
            return null
          }
        },
        enabled: !!auth.data
    })

    const { data:activeEventPronos, refetch:refetchPronos } = useQuery<Prono[] | null>({
        queryKey: [`pronos`],
        queryFn: async () => {
          try {
            const {data:pronos} = await axios.get(`events/${data[0]?.m_iEventID}/pronos`)
            return pronos
          } catch (error) {
            return null
          }
        },
        enabled: !!data
    })

    useEffect(()=>{
        if(redirectUser.current){
            redirectUser.current = false
            navigate(`/${toEventOrPartner(auth)}/${data ? (getTranslationString(i18n.language.toUpperCase() as Language, data[0], "m_sUrlNL") || data[0].m_sUrlNL) : ''}/${activeEventPronos ? findNextProno(activeEventPronos)?.m_iPronoID : ''}`)
        }
    }, [activeEventPronos])

    function findNextProno (pronos:Prono[]){
        const prono = pronos.find(i=>i.m_bActive === true && i.m_bClosed === false && isAfter(new Date(i.m_dtStart), new Date));
        if(!prono){
            return pronos[pronos.length-1]
        }
        return prono
    }

    return (
        <div className='min-h-[100dvh] bg-tifo grid md:grid-cols-2 z-0'>
            <div className='flex flex-col gap-4 justify-center mx-4 md:px-10 relative'>
                <p className='bg-tifo-light text-tifo px-3 py-1 font-semibold w-fit uppercase'>{t("home.wielrennen")}</p>
                <h1 className='text-white font-extrabold text-[52px] sm:text-[64px] uppercase leading-none'>{t("home.ben jij ook")} <br></br>{t("home.zot")}<br></br> {t("home.van koers")}</h1>
                {!auth.data && <button onClick={()=>{ showLogin() }} className='w-fit z-10 bg-transparent border border-white uppercase font-semibold text-white px-8 py-4 mt-6'>{t("home.begin met spelen")}</button>}
                {auth.data && <Link to={`/${toEventOrPartner(auth)}/${data ? (getTranslationString(i18n.language.toUpperCase() as Language, data[0], "m_sUrlNL") || data[0].m_sUrlNL) : ''}/${activeEventPronos ? findNextProno(activeEventPronos)?.m_iPronoID : ''}`} className='w-fit z-10'><button className='w-fit bg-transparent border border-white uppercase font-semibold text-white px-8 py-4 mt-6'>{t("home.begin met spelen")}</button></Link>}

                <div className='hidden lg:block w-12 h-12 border border-white absolute right-14 bottom-14'>
                    <div className='relative w-full h-full'>
                        <img src={MoveDown} className='w-6 absolute left-1/2 top-1/2 -translate-x-1/2'/>
                    </div>
                </div>

                <img src={LogoOpacity} className='absolute left-1/3 h-[80%] z-0'/>
            </div>
            <div className='bg-white hidden md:block'>
                <img src={top} className='w-full h-full object-cover'/>
            </div>
        </div>
    )
}

const HowDoesItWork = () => {
    const { t } = useTranslation()
    return (
        <div className='min-h-[100dvh] bg-tifo z-[1] relative py-32 flex flex-col gap-10 items-center'>
            <h2 className='text-white text-center font-extrabold text-[52px] sm:text-[64px] uppercase leading-none mx-4'>{t("home.hoe werkt het")}</h2>
            <p className='text-white text-center max-w-4xl leading-[2] mx-4'>{t("home.hwh")}</p>

            <div className='flex gap-8 items-center justify-center mx-4 sm:mx-0 max-w-[100dvw] overflow-hidden mt-10'>
                <div className='hidden sm:block min-w-[250px] w-[310px] h-[430px] bg-white/50 rounded-xl overflow-hidden relative'><div className='absolute w-full h-full bg-tifo/60'></div><img src={Slider001} className='w-full h-full object-cover' alt=""/></div>
                <div className='hidden sm:block min-w-[250px] w-[340px] h-[470px] bg-white/50 rounded-xl overflow-hidden relative'><div className='absolute w-full h-full bg-tifo/30'></div><img src={Slider003} className='w-full h-full object-cover' alt=""/></div>
                <div className='min-w-[250px] w-full sm:w-[360px] h-[520px] bg-white/50 rounded-xl overflow-hidden relative'><div className='absolute w-full h-full bg-tifo/0'></div><img src={Slider002} className='w-full h-full object-cover' alt=""/></div>
                <div className='hidden sm:block min-w-[250px] w-[340px] h-[470px] bg-white/50 rounded-xl overflow-hidden relative'><div className='absolute w-full h-full bg-tifo/30'></div><img src={Slider004} className='w-full h-full object-cover' alt=""/></div>
                <div className='hidden sm:block min-w-[250px] w-[310px] h-[430px] bg-white/50 rounded-xl overflow-hidden relative'><div className='absolute w-full h-full bg-tifo/60'></div><img src={Slider005} className='w-full h-full object-cover' alt=""/></div>
            </div>
        </div>
    )
}

const Join = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    function showLogin() {
        dispatch(setLoginPopup(true))
    }

    return (
        <div className='bg-white z-[1] relative py-16 pb-0 sm:py-32 sm:pt-64 flex flex-col gap-10 items-center'>
            <h2 className='text-neutral-800 text-center font-extrabold text-[52px] sm:text-[64px] uppercase leading-none mx-4'>{t("home.doe mee")}</h2>
            <p className='text-neutral-800 text-center max-w-4xl leading-[2] mx-4'>{t("home.dm")}</p>
            <button onClick={()=>{showLogin()}} className='flex items-center gap-2 font-medium p-4 hover:underline'>{t("home.ik wil inloggen")} <ChevronRight strokeWidth={1.5}/></button>
        </div>
    )
}

const Points = () => {
    const { t } = useTranslation()

    return (
        <div className='pb-32 pt-16 sm:py-32 bg-white z-[1] relative flex justify-center'>
            <div className='bg-neutral-600 max-w-[1700px] w-full mx-4 md:mx-10 rounded-xl p-4 sm:p-8 min-h-[400px] flex flex-col gap-4 justify-end relative overflow-hidden'>
                <h2 className='relative z-[2] text-white font-extrabold text-[32px] sm:text-[64px] uppercase leading-none'>{t("home.punten en beloningen")}</h2>
                <p className='relative z-[2] text-white max-w-4xl leading-[2]'>{t("home.peb")}</p>
                <div className='absolute top-0 left-0 w-full h-full bg-tifo/60 z-[1]'></div>
                <img src={prize} className='absolute top-0 left-0 w-full h-full object-cover'/>
            </div>
        </div>
    )
}

const Faq = () => {
    const { t } = useTranslation()

    return (
        <div className='py-32 bg-tifo grid md:grid-cols-2 gap-10 px-4 sm:px-10'>
            <div className='bg-white/10 rounded-xl overflow-hidden max-h-[400px] relative'>
                <div className='absolute h-full w-full bg-tifo/50 z-10'></div>
                <img src={Faq001} className='object-cover w-full h-full'/>
            </div>
            <div className='flex flex-col gap-4'>
                <h2 className='text-white font-extrabold text-[52px] sm:text-[64px] uppercase leading-none'>{t("home.nog vragen")}</h2>
                <p className='text-white font-medium text-lg'>{t("home.wij zijn er voor je")}</p>
                <Accordion type="multiple" className='text-white gap-4 flex flex-col w-full'>
                    <AccordionItem value="item-1" className='bg-white/10 px-4 rounded-md border-none'>
                        <AccordionTrigger>{t("home.hoe maak ik mijn account")}</AccordionTrigger>
                        <AccordionContent>
                        {t("home.hmima")}
                        </AccordionContent>
                    </AccordionItem>
                    <AccordionItem value="item-2" className='bg-white/10 px-4 rounded-md border-none'>
                        <AccordionTrigger>{t("home.wat voor prijzen kan ik winnen")}</AccordionTrigger>
                        <AccordionContent>
                        {t("home.wvpkiw")}
                        </AccordionContent>
                    </AccordionItem>
                    <AccordionItem value="item-3" className='bg-white/10 px-4 rounded-md border-none'>
                        <AccordionTrigger>{t("home.hoe speel ik mee")}</AccordionTrigger>
                        <AccordionContent>           
                        {t("home.hsim")}
                        </AccordionContent>
                    </AccordionItem>
                    <AccordionItem value="item-4" className='bg-white/10 px-4 rounded-md border-none'>
                        <AccordionTrigger>{t("home.hoe worden winnaars bepaald")}</AccordionTrigger>
                        <AccordionContent>           
                        {t("home.hwwb")}
                        <div className='mt-2'><Link to="/wedstrijdreglement" className='underline'>{t("home.meer details over de puntentelling")}</Link></div>
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>
    )
}

const Home = () => {
  return (
    <div className='overflow-x-hidden'>
        <Top />
        <HowDoesItWork />
        <Join />
        <Points />
        <Faq />
        <Footer className='bg-tifo/90'/>
    </div>
  )
}

export default Home